import React, { useState } from "react";
import { createuser } from "../services/userService";

const labelStyle = "my-4";
const inputStyle =
  "bg-gray-50 border border-gray-300 rounded-lg text-sm p-2 text-gray-900 w-full";
const buttonStyle =
  "mr-4 bg-navy_blue-600 hover:bg-navy_blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 mt-4";

const PopupDiv = ({ setshowPopup, setLoading }) => {
  const [status, setStatus] = useState("initial"); // 'initial', 'success', 'error'
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newUser, setNewUser] = useState({
    Name: "",
    Email: "",
    Phone_Number: "",
    Address: "",
  });
  const [copied, setCopied] = useState(false);

  const closeModal = () => {
    setshowPopup(false);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewUser({ ...newUser, [id]: value });
  };

  const onClickYes = async () => {
    const { Name, Email, Phone_Number, Address } = newUser;
    if (!Name || !Email ) {
      setErrorMessage("All fields must be filled out.");
      setStatus("error");
      return;
    }
  
    try {
      setLoading(true);
      newUser["Role"] = "user"
      let data = await createuser(newUser);
      setLoading(false);
      setSuccessMessage(
        `User added successfully. Email: ${data.Email}, Password: ${data.Password}, ID: ${data.ID}`
      );
      setStatus("success");
    } catch (error) {
      setErrorMessage(error.message || "An error occurred while adding the user.");
      setStatus("error");
      setLoading(false);
    }
  };
  

  const handleClear = () => {
    setSuccessMessage("");
    setStatus("initial");
    setNewUser({
      Name: "",
      Email: "",
      Phone_Number: "",
      Address: "",
    });
  };

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(successMessage);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setErrorMessage("An error occurred while copying to clipboard.");
      setCopied(false);
    }
  };

  return (
    <div className="shadow bg-gray-700  md:min-w-full  text-white rounded-lg w-full">
      <button
        type="button"
        className="absolute top-3 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
        data-modal-hide="popup-modal"
        onClick={closeModal}
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="p-4 md:p-5">
        <h3 className="block mb-5 text-lg font-normal">Add a new member</h3>

        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <div className="my-4">
              <label htmlFor="name" className={labelStyle}>
                Name
              </label>
              <input
                type="text"
                id="Name"
                className={inputStyle}
                placeholder="Name of the Member"
                required
                value={newUser.Name}
                onChange={handleInputChange}
              />
            </div>

            <div className="my-4">
              <label htmlFor="email" className={labelStyle}>
                Email
              </label>
              <input
                type="email"
                id="Email"
                className={inputStyle}
                placeholder="name@dsa.com"
                required
                value={newUser.Email}
                onChange={handleInputChange}
              />
            </div>
            
            <button
              data-modal-hide="popup-modal"
              type="button"
              className={buttonStyle}
              onClick={onClickYes}
            >
              Add User
            </button>
            <button
              data-modal-hide="popup-modal"
              type="button"
              className={buttonStyle}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>

          <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
            <div className="ml-2 bottom-0 h-full">
              {status === "initial" && (
                <p className="text-sm text-gray-500">
                  Please complete the registration using a valid email.
                </p>
              )}
              {status === "success" && (
                <div>
                  <p className="text-sm text-green-500">{successMessage}</p>
                  <button className={buttonStyle} onClick={copyToClipboard}>
                    {copied ? "Copied to clipboard!" : "Copy to clipboard"}
                  </button>
                </div>
              )}
              {status === "error" && (
                <p className="text-sm text-red-500">{errorMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDiv;
