import React, { Component, useEffect, useState } from "react";
import Faq from "react-faq-component";
import { getFAQs } from "../services/faqService";
import { toast } from "react-toastify";


const styles = {
    // bgColor: 'white',
    titleTextColor: "#815e2e",
    rowTitleColor: "#815e2e",
    // rowContentColor: 'grey',
    arrowColor: "#815e2e",
};


const FAQdisplay = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        let data = await getFAQs();
        setData({
            title : "FAQ",
            rows : data.map((x) => ({ title: x.question, content: x.answer }))
        });
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        toast.error("Error fetching FAQs");
      }
    };

    fetchFAQs();
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg w-full ">
      <Faq data={data} styles={styles} />
    </div>
  );
};

export default FAQdisplay;
