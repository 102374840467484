import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { getEventFeedback } from "../services/feedbackService";
import { toast } from "react-toastify";

const FeedbackDisplay = ({rowWithCols, sectionStyle, eventID, setLoading}) => {
  const [feedback, setFeedback] = useState("");
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchFeedback = async () => {
        setLoading(true);
        try {
            let feedbackData = await getEventFeedback(eventID);
            if (feedbackData) {
                setFeedback(feedbackData.feedbackText);
                setImages(feedbackData.images);
            }
        }
        catch (error) {
            toast.error("Error fetching feedback");
            console.error("Error fetching feedback:", error);
        }
        finally {
            setLoading(false);
        }
    }

    fetchFeedback();
    }, [eventID]);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className={rowWithCols + " bg-white text-navy_blue"}>
        <div className={sectionStyle + "md:w-full p-8"}>
      <h2 className="text-2xl font-bold mb-4">Event Feedback</h2>
      <p className="text-lg mb-6">{feedback}</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Event ${index}`}
              className="w-full  object-cover rounded cursor-pointer border-2 border-gray-200"
              onClick={() => openImage(image)}
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <button
              onClick={closeImage}
              className="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full focus:outline-none"
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="max-w-full max-h-screen object-contain rounded"
            />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default FeedbackDisplay;
