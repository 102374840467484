import React, { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import { Link, Navigate } from "react-router-dom";
import ProfileImage from "./ProfileImage";
import { set } from "date-fns/set";
import { toast } from "react-toastify";

const NavBar = ({ navigate, setLoading }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    try {
      const fetchUserRole = async () => {
        if (auth.user && auth.user.role) {
          return auth.user.role;
        }
        return null;
      };
  
      if (auth.user) {
        fetchUserRole().then((urole) => {
          if (urole) {
            setUserRole(urole);
          }
        });
      }
    }

    catch (error) {
      toast.error("An error occured while fetching user role")
    }
  }, [auth.user]);

  const handleNavItemClick = (path) => {
    navigate(path);
    setShowMobileMenu(false);
    setShowUserMenu(false);
  };

  const handleLogout = async (event) => {
    setLoading(true)
    event.preventDefault();

    let user = await auth.logOutAction();
    console.log(user);
    setLoading(false);
    <Navigate to="/" />;
    
    window.location.reload();
  };

  const handleProfile = () => {
    let x = userRole === "admin" ? "/a/profile" : "/u/profile";
    navigate(x);
    setShowUserMenu(false);
  };

  return (
    <nav className="absolute z-50 text-white w-full text-sm md:text-base ">
      <div className=" flex flex-wrap items-center justify-between mx-auto p-4">
        <span className="self-center font-libre whitespace-nowrap "></span>

        {auth.user && (
          <div className=" flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse ">
            <button
              type="button"
              className="border flex  bg-gray-800 rounded-full md:me-0 focus:ring-1 focus:ring-gray-300 "
              onClick={() => {
                setShowUserMenu(!showUserMenu);
                setShowMobileMenu(false);
              }}
            >
              <span className="sr-only">Open user menu</span>
              <ProfileImage
                className="w-8 h-8 rounded-full"
                src={auth.user.profile_picture}
                alt={auth.user?.name}
              />
            </button>

            <div className="z-10 absolute right-1/4 md:right-2 top-16 bg-penn_blue rounded-lg">
              <div
                className={
                  showUserMenu
                    ? ""
                    : " hidden my-10 text-base list-none  divide-y  rounded-lg shadow  "
                }
                id="user-dropdown"
              >
                <div className="px-4 py-3">
                  <span className="block font-bold">{auth.user?.username}</span>
                  <span className="block truncate font-normal">
                    {auth.user?.role}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li>
                    <button
                      className="block px-4 py-2   "
                      onClick={handleProfile}
                    >
                      Profile
                    </button>
                  </li>
                  <li>
                    <button
                      className="block px-4 py-2   "
                      onClick={handleLogout}
                    >
                      Sign out
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center  rounded-lg md:hidden "
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
            setShowUserMenu(false);
          }}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            showMobileMenu ? "absolute right-4 top-16" : "hidden "
          } bg-penn_blue md:bg-transparent  md:flex md:w-auto md:order-1 rounded-lg`}
          id="navbar-user"
        >
          {userRole === "admin"
            ? adminNavBar(handleNavItemClick)
            : userRole === "user"
            ? userNavBar(handleNavItemClick)
            : welcomeNavBar()}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

const li_style =
  "md:hover:text-navy_blue rounded p-4 focus:ring-blue-500 gap-0 cursor-pointer";

const welcomeNavBar = () => {
  return (
    <ul className="flex flex-col   font-medium  p-0 md:space-x-8 rtl:space-x-reverse md:flex-row md:hover:bg-white md:hover:text-navy_blue/60 rounded-lg ">
      <li className={li_style}>
        <a href="#" className="block  px-3 rounded  md:p-0 ">
          About
        </a>
      </li>
      <li className={li_style}>
        <a href="#" className="block  px-3 rounded  md:p-0 ">
          Contact
        </a>
      </li>
      <li className={li_style}>
        <a href="#" className="block  px-3 rounded  md:p-0 ">
          Login
        </a>
      </li>
    </ul>
  );
};

const adminNavBar = (handleNavItemClick) => {
  return (
    <ul className="flex flex-col  font-medium  p-0 md:space-x-8 rtl:space-x-reverse md:flex-row  md:hover:bg-white md:hover:text-navy_blue/60 rounded-lg">
      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/a/");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Dashboard</a>
      </li>

      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/a/events");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Events</a>
      </li>
      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/a/event/new");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Add Event</a>
      </li>
      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/a/participants");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Participants</a>
      </li>
      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/a/wines");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Wines</a>
      </li>
      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/a/faqs");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">FAQs</a>
      </li>
    </ul>
  );
};

const userNavBar = (handleNavItemClick) => {
  return (
    <ul className="flex flex-col  font-medium  p-0 md:space-x-8 rtl:space-x-reverse md:flex-row  md:hover:bg-white md:hover:text-navy_blue/60 rounded-lg">
      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/u/");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Dashboard</a>
      </li>

      <li
        className={li_style}
        onClick={() => {
          handleNavItemClick("/u/events");
        }}
      >
        <a className="block py-2 px-3 rounded  md:p-0 md:">Events</a>
      </li>
    </ul>
  );
};
