import React from "react";
import { Link } from "react-router-dom";
import WIneBackground from "../components/WIneBackground";

const Home = () => {
  return (
    <>
      <div className="relative h-full w-full items-center flex text-[#815e2e]">
        <WIneBackground />
        <div className="relative flex items-end bottom-10 md:bottom-0 h-full w-full ">
          <div className="flex w-5/6 sm:w-3/6 lg:w-2/6 h-1/2 sm:h-full bg-white md:bg-transparent rounded-tr-3xl md:rounded-none bg-opacity-60 p-6 ">
            <div className="place-self-end mb-10">
              <h2 className=" text-4xl md:text-4xl xl:text-6xl font-bold  mb-10">
                {/* <span className="text-periwinkle">Elevate Your Experience: </span> Craft Unforgettable Wine Tasting Events */}
                Wine Freaks
              </h2>
              {/* <p className="text-white text-sm md:text-lg xl:text-2xl mt-4 xl:mt-12">
                Explore, Taste, and Experience the Art of Wine Tasting with Us
              </p> */}

              <Link to="/login">
                <button className="text-base bg-[#815e2e] text-white py-2 px-4 rounded-lg hover:bg-[#815e2e]/80 focus:outline-none mt-6 xl:text-2xl xl:p-4">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
