import React from 'react'
import { stringToColour } from '../utils/suffle'

const ProfileImage = ({className, src, alt}) => {
  let dp = `https://ui-avatars.com/api/?name=${String(alt).replace(" ", "+")}&background=${stringToColour(alt) }&rounded=true`
  
  return (
    <img
      className={className}
      src={src || dp}
      alt={alt}
      onError={
        (e) => {
          e.target.onerror = null;
          e.target.src = dp;
        }
      }
    />
  )
}

export default ProfileImage