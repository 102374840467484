import React, { useEffect, useMemo, useState } from "react";
import { dynamicSort } from "../utils/dynamicSort";
import { Link, useNavigate } from "react-router-dom";
import { statusValues } from "../utils/consts";
import {
  CalendarPlus,
  CalendarSearch,
  Pencil,
  Play,
  Search,
  Trash2,
  Wine,
} from "lucide-react";

import PopupDivGen from "./GeneralPopup";
import Popup from "reactjs-popup";
import { useAuth } from "../context/authContext";
import { toast } from "react-toastify";
import {
  addBottle,
  deleteBottle,
  getBottles,
  getBottlesWithRating,
} from "../services/bottleService";
import PopupDivWine from "./AddWinePopup";

const participantsHeaders = {
  name: "Name",
  year: "Vintage",
  rating: "Rating",
  average: "Correct",
};

const TableReact = ({ setLoading }) => {
  const [activeColumn, setActiveColumn] = useState(["name"]);
  const [sortingColumn, setSortingColumn] = useState(["name"]);
  const [searchString, setSearchString] = useState("");
  const [sortingData, setSortingData] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [bottleToDelete, setWineToDelete] = useState({});
  const [bottles, setBottles] = useState([]);
  const [wineToEdit, setWineToEdit] = useState(null);

  const navigate = useNavigate();
  const auth = useAuth();

  const handleAddBottle = async () => {
    try {
      const addBottleToCollection = async () => {
        setLoading(true);
        let bid = await addBottle(wineToEdit, wineToEdit.id);
        setLoading(false);
        return bid;
      };
      let id = await addBottleToCollection();

      let bottle = { id, name: wineToEdit.name, year: wineToEdit.year };
      await fetchBottles();
      toast.success("Adding bottle successful!");
    } catch (error) {
      console.log(error);
      toast.error("Error adding new bottle");
      setLoading(false);
    }
  };

  const sortByColumn = (column) => {
    if (sortingColumn?.includes(column)) {
      const sortData = bottles.slice().sort(dynamicSort(column, "asc"));
      setSortingData(sortData);
      setSortingColumn([]);
    } else {
      const sortData = bottles.slice().sort(dynamicSort(column, "desc"));
      setSortingData(sortData);
      setSortingColumn([`${column}`]);
    }
    setActiveColumn([`${column}`]);
  };

  useMemo(() => {
    const sortedProducts = bottles.slice().sort((a, b) => a.Price - b.Price);
    setSortingData(sortedProducts);
  }, []);

  const fetchBottles = async () => {
    try {
      setLoading(true);
      getBottlesWithRating()
        .then((data) => {
          data = data.sort(dynamicSort("name", "asc"));
          setSortingData(data);
          setBottles(data);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      toast.error("An error occured while fetching wines");
    }
  };

  useEffect(() => {
    fetchBottles();
  }, []);

  const deleteWine = () => {
    try {
      console.log("deleting wine" + bottleToDelete.id);
      setLoading(true);
      deleteBottle(bottleToDelete)
        .then((res) => {
          toast.success("Wine deleted successfully");
          setShowPopup(false);
          setSortingData(
            sortingData.filter((bot) => bot.id !== bottleToDelete.id)
          );
        })
        .catch((err) => {
          toast.error("An error occured while deleting wine");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("An error occured while deleting wine");
    }
  };

  const handleDeleteWine = (data) => {
    setShowPopup(true);
    setWineToDelete(data);
  };

  const handleEditBottle = (data) => {
    setWineToEdit(data);
  };

  useEffect(() => {
    const filterData = bottles.filter((data) => {
      if (searchString === "") {
        return data;
      } else {
        return data?.name.toLowerCase().includes(searchString.toLowerCase());
      }
    });
    setSortingData(filterData);
  }, [searchString]);

  return (
    <div className="w-full h-full   flex flex-col items-center justify-center    md:place-self-center  text-navy_blue">
      <div className="w-full bg-white md:m-6 rounded-md p-8 ">
        <div className="flex flex-row place-content-between w-full">
          <h1 className="text-2xl font-medium mb-8">
            Wines List
            <div className="text-navy_blue/50 text-base">
              See Information about all wines.
            </div>
          </h1>
          <div className="place-content-center  text-white h-fit mt-2 text-sm">
            <button
              className="flex items-center max-h-min bg-navy_blue rounded-md p-2"
              onClick={() => setWineToEdit({})}
            >
              <Wine size={20} />
              <span className="ml-2">ADD WINE</span>
            </button>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:place-content-between w-full mb-6">
          <div className="flex flex-row place-content-between bg-transparent rounded-lg p-1 gap-4 mb-2 md:mb-0 md:mr-2"></div>

          <div
            id="search"
            className="flex flex-row place-content-between bg-navy_blue-900/50 rounded-lg p-1 gap-4"
          >
            <input
              type="text"
              placeholder="Search"
              className="bg-transparent text-navy_blue p-1"
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button className="bg-navy_blue rounded-md p-1 text-white">
              <Search size={20} />
            </button>
          </div>
        </div>

        <div className="h-full overflow-y-scroll overflow-x-scroll md:overflow-auto  mt-2">
          <table className="w-full table-auto overflow-scroll md:overflow-auto  text-left font-inter border-separate border-spacing-y-0 borer ">
            <thead className="bg-egyptian_blue-500 rounded-lg text-base text-white font-semibold  ">
              <tr className="">
                {Object.keys(participantsHeaders).map((header, index) => (
                  <th
                    className="py-3 px-3 text-white whitespace-nowrap group "
                    id={index}
                  >
                    <div className="flex items-center">
                      <svg
                        className={` h-4 cursor-pointer ${
                          activeColumn?.includes(header)
                            ? "text-white"
                            : "text-[#BCBDBE] group-hover:text-black rotate-180"
                        } ${
                          sortingColumn?.includes(header)
                            ? "rotate-180"
                            : "rotate-0"
                        }
           `}
                        onClick={() => sortByColumn(header)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 14l-7 7m0 0l-7-7m7 7V3"
                        />
                      </svg>
                      <span
                        className="cursor-pointer pl-1 text-center"
                        onClick={() => sortByColumn(header)}
                      >
                        {participantsHeaders[header]}
                      </span>
                    </div>
                  </th>
                ))}

                <th className="py-3 px-3 text-white whitespace-nowrap group ">
                  <div className="flex items-center">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {sortingData?.length ? (
                sortingData?.map((data, index) => (
                  <tr
                    key={index}
                    className="hover:bg-navy_blue/20 cursor-pointer"
                  >
                    <td className=" py-2 px-3 font-normal text-base border-t whitespace-nowrap text-left min-w-64 text-wrap ">
                      {data?.name}
                    </td>
                    <td className=" py-2 px-3 font-normal text-base border-t whitespace-nowrap text-left text-wrap ">
                      {data?.year}
                    </td>
                    <td className=" py-2 px-3 font-normal text-base border-t whitespace-nowrap text-left  text-wrap ">
                      {(data?.rating).toFixed(2)}
                    </td>
                    <td className=" py-2 px-3 font-normal text-base border-t whitespace-nowrap text-left  text-wrap ">
                      {(data?.average).toFixed(2)}%
                    </td>

                    {auth.user?.role === "admin" ? (
                      <td className="py-5 px-4 text-base font-normal border-t">
                        <div className="flex flex-row gap-2">
                          <button
                            className="bg-red-600 text-white rounded-full p-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDeleteWine(data);
                            }}
                          >
                            <Trash2 size={20} />
                          </button>

                          <button
                            className={
                              "bg-green-600 text-white rounded-full p-2" +
                              (data?.Status === "Past" ? " hidden" : "")
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEditBottle(data);
                            }}
                          >
                            <Pencil size={20} />
                          </button>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))
              ) : (
                <tr className=" p-6">No Data</tr>
              )}
              <tr>
                <td colSpan={6} className="border-t"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Popup
        position="center center"
        closeOnDocumentClick
        modal
        contentStyle={{ border: "none", background: "none",width: "max-content"  }}
        open={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <PopupDivGen
          message={`Are you sure you want to delete this wine?`}
          setshowPopup={setShowPopup}
          onYes={deleteWine}
        />
      </Popup>

      <Popup
        position="center center"
        closeOnDocumentClick
        modal
        contentStyle={{ border: "none", background: "none", width: "max-content" }}
        open={wineToEdit ? true : false}
        onClose={() => setWineToEdit(null)}
      >
        <PopupDivWine
          wine={wineToEdit}
          setWine={setWineToEdit}
          setshowPopup={setWineToEdit}
          onYes={handleAddBottle}
        />
      </Popup>
    </div>
  );
};
export default TableReact;
