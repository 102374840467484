import React, { useEffect, useState } from "react";
import SmallStatCard from "./SmallStatCard";
import { Calendar, MoveUpRight, Users2, Wine } from "lucide-react";
import { getCounts } from "../services/statService";
import { toast } from "react-toastify";
import CurrentEvents from "./HapenningNow";
import { useAuth } from "../context/authContext";
import FAQdisplay from "./FAQdisplay";

const DashBoardSmallStats = ({ sectionStyle, setLoading }) => {
  const [counts, setCounts] = useState({});
  const auth = useAuth();

  useEffect(() => {
    try {
      setLoading(true);
      getCounts().then((data) => {
        console.log(data);
        setCounts(data);
      });
    } catch (error) {
      toast("Error loading data");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className="flex flex-col md:flex-row gap-4 md:h-full auto-cols-auto">
      <div className={`${sectionStyle} md:min-h-min md:w-full text-xl`}>
        <div className="text-6xl font-hand">Welcome</div> to
        <div className="text-3xl font-sans font-semibold">
          Daniel’s wine tasting calculator
        </div>
      </div>

      {auth.user?.role === "user" && (
        <div
          className={`${sectionStyle} md:min-h-min md:w-full text-xl md:hidden`}
        >
          <CurrentEvents setLoading={setLoading} />
        </div>
      )}

      {auth.user?.role === "user" && (
        <div
          className={`${sectionStyle} md:min-h-min md:w-full text-xl md:hidden`}
        >
          <FAQdisplay />
        </div>
      )}

      <div
        className={`${sectionStyle} md:min-h-min md:w-full flex flex-col md:flex-row gap-4`}
      >
        <SmallStatCard
          name="Total Events"
          value={counts?.events}
          icon={<Calendar size={56} />}
          height={1096}
          width="1/3"
        />
        <SmallStatCard
          name="Total Participants"
          value={counts?.users}
          icon={<Users2 size={56} />}
          height={1096}
          width="1/3"
        />
        <SmallStatCard
          name="Total Wines"
          value={counts?.wines}
          icon={<Wine size={56} />}
          height={1096}
          width="1/3"
        />
      </div>
    </div>
  );
};

export default DashBoardSmallStats;
