import React, { useEffect, useState } from "react";

const labelStyle = "my-4 mr-4 text-right";
const inputStyle =
  "bg-gray-50 border border-gray-300 rounded-lg text-sm p-2 text-gray-900 w-1/2";
const buttonStyle =
  "mr-4 bg-navy_blue-600 hover:bg-navy_blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 mt-4";

const PopupDivWine = ({ wine, setWine, setshowPopup, onYes }) => {
  const [newBottle, setNewBottle] = useState({});

  useEffect(() => {
    setNewBottle(wine);
  }, [wine]);

  const closeModal = () => {
    setshowPopup(null);
  };

  const onClickYes = () => {
    onYes();
    setshowPopup(null);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setWine({ ...newBottle, name: value });
  };

  return (
    <div className=" bg-white shadow dark:bg-gray-700 m-0">
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="popup-modal"
        onClick={closeModal}
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="p-4 md:p-5 text-center text-white">
        <h3 className="block mb-5 text-xl font-bold">
          Are you sure you want to add/edit the following bottle?
        </h3>
        <div className="my-4">
          <label htmlFor="name" className={labelStyle}>
            Name
          </label>
          <input
            type="text"
            id="Name"
            className={inputStyle}
            placeholder="Name of the Bottle"
            required
            value={newBottle?.name}
            onChange={handleInputChange}
          />
        </div>

        <div className="my-4">
          <label htmlFor="email" className={labelStyle}>
            Vintage
          </label>
          <input
            type="text"
            id="Email"
            className={inputStyle}
            placeholder="Vintage"
            required
            value={newBottle?.year}
            onChange={(data) => {
                setWine({ ...newBottle, year: data.target.value });
            }}
          />
        </div>
        <button
          data-modal-hide="popup-modal"
          type="button"
          className="text-white bg-navy_blue-600 hover:bg-navy_blue-800 focus:ring-4 focus:outline-none focus:ring-navy_blue-300 dark:focus:ring-navy_blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
          onClick={onClickYes}
        >
          Yes, I'm sure
        </button>
        <button
          data-modal-hide="popup-modal"
          type="button"
          className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={closeModal}
        >
          No, cancel
        </button>
      </div>
    </div>
  );
};

export default PopupDivWine;
