import React, { useEffect, useMemo, useState } from "react";
import { dynamicSort } from "../utils/dynamicSort";

import Medal from "../components/Medal";
import { FaGhost, FaLightbulb, FaWineBottle } from "react-icons/fa";
import { MdMood, MdOutlineModeNight, MdOutlineMoodBad } from "react-icons/md";
import { getBottlesByIDs } from "../services/bottleService";
import { toast } from "react-toastify";

const participantsHeaders = {
  Name: "Wine",
  Rating: "Rating",
  CorrectGuesses: "No of Correct Guesses",
};

const TableWines = ({ setLoading, wines, rowWithCols, sectionStyle }) => {
  const [activeColumn, setActiveColumn] = useState(["Name"]);
  const [sortingColumn, setSortingColumn] = useState(["Name"]);
  const [wineBottles, setWineBottles] = useState([]);
  const [sortingData, setSortingData] = useState(wineBottles);

  const [winesSortedByRating, setWinesSortedByRating] = useState([]);
  const [winesSortedByGuesses, setWinesSortedByGuesses] = useState([]);
  const [wineBottleNames, setWineBottleNames] = useState([]);
  const [wineBottleYears, setWineBottleYears] = useState([]);

  useEffect(() => {
    if (wines) {
      console.log("wines", wines);
      let winesSortedByRating = [...wines].sort((a, b) => b.Rating - a.Rating);
      let winesSortedByGuesses = [...wines].sort(
        (a, b) => b.CorrectGuesses - a.CorrectGuesses
      );
      setWinesSortedByRating(winesSortedByRating);
      setWinesSortedByGuesses(winesSortedByGuesses);
    }
  }, [wines]);

  useEffect(() => {
    try {
      if (wines) {
        const wineIDs = wines.map((wine) => wine.Wine);
        console.log(wineIDs);
        getBottlesByIDs(wineIDs).then((data) => {
          // set wine bottle names
          setWineBottleNames(
            data.reduce(
              (acc, curr) => ({
                ...acc,
                [curr?.id]: curr?.name,
              }),
              {}
            )
          );

          // set wine bottle years
          setWineBottleYears(
            data.reduce(
              (acc, curr) => ({
                ...acc,
                [curr?.id]: curr?.year,
              }),
              {}
            )
          );

          let wineWithoutName = wines.map((wine) => {
            console.log(data);
            let wineData = data.find((x) => x?.id === wine.Wine);
            return {
              ...wine,
              Name: wineData?.name || "Unknown",
              ...wineData,
            };
          });

          setWineBottles(wineWithoutName);
          setSortingData(wineWithoutName);
        });
      }
    } catch (error) {
      toast.error("An error occured while fetching wine bottles");
    }
  }, []);

  const sortByColumn = (column) => {
    if (sortingColumn?.includes(column)) {
      const sortData = wineBottles.slice().sort(dynamicSort(column, "asc"));
      setSortingData(sortData);
      setSortingColumn([]);
    } else {
      const sortData = wineBottles.slice().sort(dynamicSort(column, "desc"));
      setSortingData(sortData);
      setSortingColumn([`${column}`]);
    }
    setActiveColumn([`${column}`]);
  };

  useMemo(() => {
    const sortedProducts = wineBottles
      .slice()
      .sort((a, b) => a.Price - b.Price);
    setSortingData(sortedProducts);
  }, []);

  return (
    <>
      <div name="ranking" className={rowWithCols}>
        <div className={sectionStyle + "md:w-full bg-black"}>
          <div className="flex flex-col md:flex-row justify-around my-2">
            <Medal
              title="Best Rated Wine Bottle"
              description={
                wineBottleNames[
                  winesSortedByRating[winesSortedByRating.length - 1]?.Wine
                ]
              }
              year={
                wineBottleYears[
                  winesSortedByRating[winesSortedByRating.length - 1]?.Wine
                ]
              }
              icon={<MdMood />}
              bgColor="bg-blue-500"
            />
            <Medal
              title="Lowest Rated Wine Bottle"
              description={wineBottleNames[winesSortedByRating[0]?.Wine]}
              year={wineBottleYears[winesSortedByRating[0]?.Wine]}
              icon={<MdOutlineMoodBad />}
              bgColor="bg-red-500"
            />
            <Medal
              title="Most Guessed Wine Bottle"
              description={wineBottleNames[winesSortedByGuesses[0]?.Wine]}
              year={wineBottleYears[winesSortedByGuesses[0]?.Wine]}
              icon={<FaLightbulb />}
              bgColor="bg-green-500"
            />
            <Medal
              title="Least Guessed Wine Bottle"
              description={
                wineBottleNames[
                  winesSortedByGuesses[winesSortedByGuesses.length - 1]?.Wine
                ]
              }
              year={wineBottleYears[
                winesSortedByGuesses[winesSortedByGuesses.length - 1]?.Wine
              ]}
              icon={<FaGhost />}
              bgColor="bg-yellow-500"
            />
          </div>
        </div>
      </div>

      <div name="ranking" className={rowWithCols}>
        <div className={sectionStyle + "md:w-full bg-black"}>
          <div className="w-full h-full   flex flex-col items-center justify-center    md:place-self-center  text-navy_blue">
            <div className="w-full bg-white md:m-6 rounded-md p-8 ">
              <div className="flex flex-row place-content-between w-full">
                <h1 className="text-2xl font-medium mb-8">
                  Wines List
                  <div className="text-navy_blue/50 text-base">
                    Information about all the wines tasted in the event.
                  </div>
                </h1>
              </div>

              <div className=" overflow-y-scroll overflow-x-scroll md:overflow-auto  mt-2">
                <table className="w-full table-auto overflow-scroll md:overflow-auto  text-left font-inter border-separate border-spacing-y-0 borer ">
                  <thead className="bg-egyptian_blue-500 rounded-lg text-base text-white font-semibold  ">
                    <tr className="">
                      {Object.keys(participantsHeaders).map((header, index) => (
                        <th
                          className="py-3 px-3 text-white whitespace-nowrap group "
                          id={index}
                        >
                          <div className="flex items-center">
                            <svg
                              className={` h-4 cursor-pointer ${
                                activeColumn?.includes(header)
                                  ? "text-white"
                                  : "text-[#BCBDBE] group-hover:text-black rotate-180"
                              } ${
                                sortingColumn?.includes(header)
                                  ? "rotate-180"
                                  : "rotate-0"
                              }
           `}
                              onClick={() => sortByColumn(header)}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 14l-7 7m0 0l-7-7m7 7V3"
                              />
                            </svg>
                            <span
                              className="cursor-pointer pl-1 text-center"
                              onClick={() => sortByColumn(header)}
                            >
                              {participantsHeaders[header]}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {sortingData?.map((data, index) => (
                      <tr key={index}>
                        <td className=" py-2 px-3 font-normal text-base border-t whitespace-nowrap flex text-left ">
                          <div>
                            <p>{data.Name}</p>
                            <p className="text-sm font-thin text-gray-500">
                              {data.year}
                            </p>
                          </div>
                        </td>
                        <td className="py-2 px-3 text-base font-normal border-t whitespace-nowrap ">
                          {(data?.Rating).toFixed(0)}
                        </td>
                        <td className="py-2 px-3 text-base font-normal border-t ">
                          {data?.CorrectGuesses}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={6} className="border-t"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TableWines;
