import React from "react";
import SortableTable from "../../components/SortableTableParticipants";
const Participants = ({ setLoading }) => {
  return (
    <>
      <div className="flex flex-col md:flex-col gap-4 mt-[calc(100dvh/8)] md:m-[calc(100dvh/8)] place-self-center w-full h-min  backdrop-blur-sm rounded-md shadow-lg ">
      
          <SortableTable setLoading={ setLoading } />

      </div>
    </>
  );
};

export default Participants;
