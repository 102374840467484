// src/components/Admin.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { MoveUpRight } from "lucide-react";
import { getAllUsers } from "../../services/userService";

import ProfileImage from "../../components/ProfileImage";
import { useNavigate } from "react-router-dom";
import DashBoardSmallStats from "../../components/DashBoardSmallStats";
import RecentEvents from "../../components/RecentEvents";

const sectionStyle =
  " text-navy_blue p-4 rounded-lg bg-white/90 backdrop-blur-sm ";
const rowWithCols =
  "w-full flex flex-col md:flex-row gap-4  auto-cols-auto items-center  ";

const Admin = ({ setLoading }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [topParticipants, setTopParticipants] = useState([]);
  

  useEffect(() => {
    const fetchTopParticipants = async () => {
      setLoading(true);
      let data = await getAllUsers();
      data = data.sort((a, b) => b?.Score - a?.Score).filter(x => x.Status != "inactive" && x.Role != "admin").slice(0, 5);
      setTopParticipants(data);
      setLoading(false);
    };
    fetchTopParticipants();
  }, []);

  

  return (
    <div className="flex flex-col md:flex-col gap-4 p-6 mt-[calc(100dvh/8)] place-self-start w-full ">
      <DashBoardSmallStats sectionStyle={sectionStyle} setLoading={setLoading}  />
      <div className="flex flex-col md:flex-row gap-4 md:h-full auto-cols-auto ">
        <div className={`${sectionStyle} md:min-h-min md:w-full`}>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold text-gray-800">
              TOP PARTICIPANTS
            </h1>
            <button
              className="  text-navy_blue font-bold py-2 px-4 rounded hover:bg-navy_blue/20"
              onClick={() => {
                let u = String(auth.user?.role).charAt(0);
                navigate(`/${u}/participants`);
              }}
            >
              <div className="flex items-center gap-2">
                <div className="mr-2 p-2 bg-white rounded-full">
                  <MoveUpRight size={20} strokeWidth={2} absoluteStrokeWidth />
                </div>
                SEE ALL
              </div>
            </button>
          </div>

          <div className="p-2 bg-gray-100 rounded-lg shadow-lg">
            <div className="flex flex-col gap-2">
              {topParticipants.map((participant, index) => (
                <div
                  key={index}
                  className="flex items-center p-2 px-4 bg-white rounded-lg shadow-md w-full"
                >
                  <div className="text-right mr-6">
                    <p className="text-xl font-bold text-navy_blue-500">
                      {index + 1}
                    </p>
                  </div>
                  <ProfileImage
                    src={participant.Profile_Picture_URL}
                    alt={participant.Name}
                    className="w-10 h-10 rounded-full mr-4"
                  />
                  <div className="flex-grow">
                    <h2 className="text-base font-semibold text-gray-800">
                      {participant.Name}{participant.Status == "inactive" ? (
                            <span className="ml-2 font-thin bg-red-700 text-sm text-white px-2 rounded-2xl py-px">
                              Inactive
                            </span>
                          ) : null}
                    </h2>
                    <p className="text-gray-600 text-sm">
                      Number of Events: {participant.NoOfEvents}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-xl font-bold text-navy_blue-500">
                      Score: {participant.Score}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <RecentEvents setLoading={setLoading} sectionStyle={sectionStyle} />
      </div>

    </div>
  );
};

export default Admin;
