import { db, authAdmin } from "../config/firebase";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { calculateScoreofUsers } from "./scoreService";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { sendEmail } from "./emailService";

const getUserDataByID = async (id) => {
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  data["Email"] =
    data["Email"] ||
    `${data.Name.replace(/\s/g, "").toLowerCase()}@example.com`;
  return data;
};

const getAllUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  let data = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });

  data = calculateScoreofUsers(data);
  return data;
};

const createuser = async (data) => {
  try {
    console.log(data);
    let email = data.Email;
    let password =
      String(email).split("@")[0] + parseInt(Math.random() * 10000);

    const userCredential = await createUserWithEmailAndPassword(
      authAdmin,
      email,
      password
    );
    const user = userCredential.user;
    let uid = user.uid; // Use uid instead of id
    data = { ...data, Joined_Date: new Date() };

    console.log(uid);

    await setDoc(doc(db, "users", uid), data);
    console.log(uid);

    sendEmail({ ...data, Password: password });

    return { Email: email, Password: password, ID: uid };
  } catch (error) {
    console.log("Error c u", error);
    throw new Error(
      JSON.stringify(error.message) ||
        "An error occurred while adding the user."
    );
  }
};

const deleteUserByID = async (id) => {
  try {
    let res = await fetch(
      "https://delete-user-by-uid-vkgq3eaiba-uc.a.run.app/?id=" + id
    );
    console.log(res);

    if (res.status === 200) {
      let usedDoc = doc(db, "users", id);
      let userData = await getDoc(usedDoc);

      // set status to inactive
      await setDoc(usedDoc, { ...userData.data(), Status: "inactive" });
    } else {
      throw new Error("Deleting user from auth failed");
    }
  } catch (error) {
    console.log("Error deleting user", error);
    throw new Error("An error occurred while deleting user " + error);
  }
};

export { getUserDataByID, getAllUsers, createuser, deleteUserByID };
