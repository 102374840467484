import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

const updateFAQ = async (id, data) => {
  const docRef = doc(db, "faqs", id);
  await updateDoc(docRef, data);
};

const addFAQ = async (data) => {
  await addDoc(collection(db, "faqs"), data);
};

const deleteFAQ = async (id) => {
  const docRef = doc(db, "faqs", id);
  await deleteDoc(docRef);
};

const getFAQs = async () => {
  const querySnapshot = await getDocs(collection(db, "faqs"));
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export { getFAQs, addFAQ, deleteFAQ, updateFAQ };