import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

import { calculateScore } from "./scoreService";

const addAttempt = async (attempt, eventID, uid, bottles) => {
  try {
    let eventdocRef = doc(db, "events", eventID);
  let userdocRef = doc(db, "users", uid);

  const eventDoc = await getDoc(eventdocRef);
  const userDoc = await getDoc(userdocRef);

  if (!eventDoc.exists() || !userDoc.exists()) {
    throw new Error("Event or User not found");
  }

  let event = eventDoc.data();
  let user = userDoc.data();

  event.Attempts[uid] = attempt;

  if (!user.Events) {
    user.Events = {};
  }
  user.Events[eventID] = calculateScore(attempt, bottles);

  console.log("Event", event);
  console.log("User", user);

  await updateDoc(eventdocRef, {
    Attempts: event.Attempts,
  });

  await updateDoc(userdocRef, {
    Events: user.Events,
  });
  }

  catch (error) {
    console.error("Error adding attempt: ", error);
    throw new Error(error?.message || "Error adding attempt");
  }
};

export { addAttempt };
