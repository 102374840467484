import React, { useEffect, useState } from 'react';
import { differenceInMonths, differenceInDays, format } from 'date-fns';
import { FaClock } from 'react-icons/fa';

const Countdown = ({ eventDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({ months: 0, days: 0 });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const event = new Date(eventDate);

      const months = differenceInMonths(event, now);
      const days = differenceInDays(event, now) - months * 30;

      setTimeRemaining({ months, days });
    };

    calculateTimeRemaining();
    const timer = setInterval(calculateTimeRemaining, 1000 * 60 * 60 * 24); // Update every day

    return () => clearInterval(timer);
  }, [eventDate]);

  return (
    <div className="flex flex-col items-center justify-center  p-4">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full text-center">
        <FaClock className="text-4xl text-blue-500 mb-4 mx-auto" />
        <h1 className="text-2xl font-bold mb-2">The event will start after</h1>
        <div className="flex justify-center items-center text-lg text-gray-800">
          <div className="flex flex-col items-center mx-4">
            <span className="text-3xl font-bold">{timeRemaining.months}</span>
            <span>Months</span>
          </div>
          <div className="flex flex-col items-center mx-4">
            <span className="text-3xl font-bold">{timeRemaining.days}</span>
            <span>Days</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
