import React from "react";
import backgroundImage from "../assests/CircleArt.jpg";

const WIneBackground = ({isBlurred}) => {
  return (
    <div>
      <div
        className={"absolute inset-0 bg-cover bg-bottom w-full overflow-x-hidden"  + (isBlurred ? "" : "")}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <div className={"absolute inset-0 bg-black " +  (isBlurred ? "opacity-50" : "opacity-5")}  />
    </div>
  );
};

export default WIneBackground;
