import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, store } from "../config/firebase";
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const getEventFeedback = async (eventID) => {
  try {
    const docRef = doc(db, "feedbacks", eventID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }

    return null;
  } catch (error) {
    console.error("Error getting feedback:", error);
    throw new Error("Error getting feedback");
  }
};

const setEventFeedback = async (feedback) => {
  let eventID = feedback.eventID;
  let images = feedback.images;
  let imagesToUpload = images.filter((image) => image.isNew);
  let feedbackText = feedback.feedbackText;

  try {
    const uploadPromises = imagesToUpload.map(async (imageObj) => {
      const { file } = imageObj;
      const storageRef = ref(store, `event_images/${eventID}/${file.name}`);
      await uploadBytes(storageRef, file);
      return getDownloadURL(storageRef);
    });

    let imageUrls = await Promise.all(uploadPromises);
    imageUrls = [
      ...imageUrls,
      ...images.filter((image) => !image.isNew).map((image) => image.url),
    ];

    let feedbackData = {
      feedbackText,
      images: imageUrls,
    };

    await setDoc(doc(db, "feedbacks", eventID), feedbackData, { merge: true });

    return true;
  } catch (error) {
    console.error("Error saving feedback:", error);
    throw new Error("Error saving feedback");
  }
};

export { setEventFeedback , getEventFeedback};
