import React, { useEffect, useState } from "react";
import { getUserDataByID } from "../services/userService";
import ProfileImage from "./ProfileImage";
import { toast } from "react-toastify";
import { getBottlesByIDs } from "../services/bottleService";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const LeaderboardTable = ({ users, wines }) => {
  const [userData, setUserData] = useState([]);
  const [wineBottleNames, setWineBottleNames] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    try {
      if (wines) {
        getBottlesByIDs(wines).then((data) => {
          // set wine bottle names
          setWineBottleNames(
            data.reduce(
              (acc, curr) => ({
                ...acc,
                [curr?.id]: curr?.name || "Unknown",
              }),
              {}
            )
          );
        });
      }
    } catch (error) {
      toast.error("An error occurred while fetching wine bottles");
    }
  }, [wines]);

  useEffect(() => {
    try {
      async function fetchUserData() {
        const userDataPromises = users.map(async (user) => {
          const player = await getUserDataByID(user.id);
          return {
            Name: player.Name,
            Email: player.Email,
            Profile_Picture_URL: player.Profile_Picture_URL,
            ...user,
            ...player,
          };
        });

        const userDataArray = await Promise.all(userDataPromises);
        setUserData(userDataArray);
      }

      fetchUserData();
    } catch (error) {
      toast.error("An error occurred while fetching user data");
    }
  }, [users]);

  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const TABLE_HEAD = ["Rank", "Name", "Result", "Score", "Actions"];

  return (
    <div className="overflow-y-scroll overflow-x-scroll md:overflow-auto mt-2">
      <h3 className="font-bold text-2xl mb-6">Leaderboard</h3>
      <table className="w-full table-auto overflow-scroll md:overflow-auto text-left font-inter border-separate border-spacing-y-0">
        <thead className="rounded-t-xl bg-gray-300/80">
          <tr className="rounded-t-xl">
            {TABLE_HEAD.map((head) => (
              <th key={head} className="border-b border-blue-gray-100 p-4">
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userData.map((user, index) => {
            const isLast = index === userData.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-gray-300";
            const isExpanded = expandedRows.includes(index);

            return (
              <React.Fragment key={index}>
                <tr className="even:bg-gray-200">
                  <td className={classes}>{index + 1}</td>
                  <td className={classes}>
                    <div className="flex flex-row">
                      <div className="h-10 w-10 mx-2 place-self-center">
                        <ProfileImage
                          alt={user?.Name}
                          src={user?.Profile_Picture_URL}
                          className="relative inline-block h-full -translate-x-0.5 !rounded-full object-cover object-center w-full"
                        />
                      </div>
                      <div>
                        <h3 className="font-semibold text-lg">
                          {user.Name}
                          {user.Status == "inactive" ? (
                            <span className="ml-2 font-thin bg-red-700 text-sm text-white px-2 rounded-2xl py-px">
                              Inactive
                            </span>
                          ) : null}
                        </h3>
                        <p className="text-sm text-neutral-700/50">
                          {user.Email}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    {user?.Result.Correct}/{user?.Result.Total}
                  </td>
                  <td className={classes}>{user?.Result.Score}</td>
                  <td className={classes}>
                    {user.Guesses.length ? (
                      <button onClick={() => toggleRow(index)}>
                        {isExpanded ? "Hide" : "Show"}
                      </button>
                    ) : (
                      <p className="text-gray-600">No guesses</p>
                    )}
                  </td>
                </tr>
                {isExpanded && (
                  <tr className="even:bg-gray-200 ">
                    <td></td>
                    <td colSpan={4}>
                      <table className="w-full mt-4 mb-10">
                        <thead>
                          <tr>
                            <th>Correct Wine</th>
                            <th>Guessed Wine</th>
                            <th>Rating Given</th>
                          </tr>
                        </thead>
                        <tbody>
                          {wines.map((wine, wineIndex) => (
                            <tr key={wineIndex} className="even:bg-gray-100">
                              <td className="p-2">{wineBottleNames[wine] || "Unknown"}</td>
                              <td className="p-2 flex items-center">
                                {wine === user?.Guesses[wineIndex] ? (
                                  <AiOutlineCheckCircle className="text-green-500 mr-2" />
                                ) : (
                                  <AiOutlineCloseCircle className="text-red-500 mr-2" />
                                )}
                                {wineBottleNames[user?.Guesses[wineIndex]] || "Unknown"}
                              </td>

                              <td className="p-2">
                                {user?.Ratings[wineIndex]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderboardTable;
