const calculateScoreOfEvent = (events) => {
  for (let event of events) {
    let correctOrder = event.Bottles;
    let attempts = event.Attempts;
    let totalScore = 0;

    let totalRatings = correctOrder.map(() => 0);
    let totalCorrectGuesses = correctOrder.map(() => 0);
    event["WineRatings"] = []
    let totalAttempts = 0

    for (let attempti of Object.keys(attempts)) {
      let attempt = attempts[attempti];
      let guessedOrder = attempt.Guesses;

      if (guessedOrder?.length) {
        totalAttempts += 1
        // Calculate the score based on correct guesses
        let score = guessedOrder.reduce((acc, guess, i) => {
          return acc + (guess === correctOrder[i] ? 1 : 0);
        }, 0);
        totalScore += score / guessedOrder.length;
        attempt["Result"] = {
          Score: Math.round(score / guessedOrder.length * 100) / 100,
          Correct: score,
          Total: guessedOrder.length,
        };

        // calculating the ratings of wines
        let ratings = attempt.Ratings;
        totalRatings = totalRatings.map((rating, i) => rating + ratings[i]);

        // calculating the correct guesses of wines
        let correctGuesses = guessedOrder.map((guess, i) =>
          guess === correctOrder[i] ? 1 : 0
        );
        totalCorrectGuesses = totalCorrectGuesses.map(
          (correctGuess, i) => correctGuess + correctGuesses[i]
        );

      } else {
        attempt["Result"] = {
          Score: 0,
          Correct: 0,
          Total: 0,
        };
      }
      attempt["id"] = attempti;
    }
    event["AverageScore"] = (totalScore / totalAttempts).toFixed(
      2
    );

    correctOrder.forEach((element, index) => {
      event["WineRatings"].push({
        Wine: element,
        Rating: totalRatings[index],
        CorrectGuesses: totalCorrectGuesses[index],
        TotalGuesses: totalAttempts,
      });
    });
  }

  return events;
};

const calculateScoreofUsers = (users) => {
  for (let user of users) {
    let events = user["Events"];
    let tscore = 0;
    let nevents = 0;

    if (events) {
      for (let event of Object.values(events)) {
        tscore += event["Score"];
      }
      nevents = Object.values(events).length;
    }

    user["NoOfEvents"] = nevents;
    user["Score"] = tscore.toFixed(2);
    if (!user["Status"]) {
      user["Status"] = "active"
    }
  }

  return users;
};

const calculateScore = (attempt, correctOrder) => {
  let guessedOrder = attempt.Guesses;

  let score = guessedOrder.reduce((acc, guess, i) => {
    return acc + (guess === correctOrder[i] ? 1 : 0);
  }, 0);

  return {
    Correct: score,
    Total: correctOrder.length,
    Score: (score / correctOrder.length),
    Ratings: attempt.Ratings,
  };
};

export { calculateScoreOfEvent, calculateScoreofUsers, calculateScore };
