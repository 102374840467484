import { db } from "../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  query,
  documentId,
  where,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const getBottles = async () => {
  const querySnapshot = await getDocs(collection(db, "wines"));
  let data = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });

  return data;
};

const getBottlesByIDs = async (ids) => {
  const queryS = query(collection(db, "wines"), where(documentId(), "in", ids));
  const querySnapshot = await getDocs(queryS);
  let dtaDic = {};
  let data = querySnapshot.docs.forEach((doc) => {
    dtaDic[doc.id] = { ...doc.data(), id: doc.id };
  });

  let dataInOrder = ids.map((x) => dtaDic[x]);

  return dataInOrder;
};

const addBottle = async (bottle, bid) => {
  // Add a new document with a generated id.
  if (!bid) {
    const docRef = await addDoc(collection(db, "wines"), bottle);
    return docRef.id;
  }
  const docRef = doc(db, "wines", bid);
  await updateDoc(docRef, bottle);
  return bid;
};

const deleteBottle = async (bottle) => {
  await deleteDoc(doc(db, "wines", bottle.id));
};

const getBottlesWithRating = async () => {
  const eventquerySnapshot = await getDocs(collection(db, "events"));
  let bottleRatings = {};

  eventquerySnapshot.docs.forEach((doc) => {
    let data = doc.data();
    let attempts = data.Attempts;
    let correctOrder = data.Bottles;
    for (let attempt of Object.values(attempts)) {
      let guesses = attempt.Guesses;
      for (let i = 0; i < guesses.length; i++) {
        let guess = guesses[i];
        let correct = correctOrder[i];

        if (!bottleRatings[correct]) {
          bottleRatings[correct] = { rating: 0, correct: 0, total: 0 };
        }

        bottleRatings[correct].total += 1;
        if (guess === correct) {
          bottleRatings[correct].correct += 1;
        }
        let N = correctOrder.length;
        bottleRatings[correct].rating += (N - attempt.Ratings[i] + 1) / N;
      }
    }
  });

  let winequerySnapshot = await getDocs(collection(db, "wines"));
  let wines = winequerySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });

  let wineRatings = wines.map((wine) => {
    let rating = bottleRatings[wine.id];
    if (!rating) {
      rating = { rating: 0, correct: 0, total: 0, average: 0 };
    }

    if (rating.total) {
      rating.average = rating.correct / rating.total * 100;
    }
    else {
      rating.average = 0;
    }

    return { ...wine, ...rating };
  });

  return wineRatings;
};

export {
  getBottles,
  addBottle,
  getBottlesByIDs,
  deleteBottle,
  getBottlesWithRating,
};
