import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";
import { createEvent } from "../../services/eventService";
import WineSelector from "../../components/WineSelector";
import ParticipantSelector from "../../components/ParticipantSelector";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllUsers } from "../../services/userService";
import { getBottles } from "../../services/bottleService";

const AddEvent = ({ setLoading }) => {
  const [event, setEvent] = useState({ Date: new Date() });
  const [bottlesForEvent, setBottlesForEvent] = useState([]);
  const [participantsForEvent, setParticipantsForEvent] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [bottles, setBottles] = useState([]);
  const [selectedBottles, setSelectedBottles] = useState([]);

  const location = useLocation();
  const { eventDataExisting } = location.state || {};

  const navigate = useNavigate();

  const sectionStyle =
    " text-navy_blue p-4 rounded-lg bg-white/90 backdrop-blur-sm ";

  useEffect(() => {
    if (eventDataExisting && participants) {
      // console.log("setting event data from existing");
      setEvent({
        Date: new Date(eventDataExisting.Date),
        Title: eventDataExisting.Title,
        Description: eventDataExisting.Description,
      });
      document.getElementById("title").value = eventDataExisting.Title;
      document.getElementById("description").value =
        eventDataExisting.Description;
      if (bottlesForEvent.length === 0 && bottles.length > 0) {
        setBottlesForEvent(
          eventDataExisting.Bottles.map((x) => ({
            id: x,
            name: bottles.find((b) => b.id === x)?.name,
            year: bottles.find((b) => b.id === x)?.year,
          }))
        );
      }

      setParticipantsForEvent(
        Object.keys(eventDataExisting.Attempts).map((x) => ({
          id: x,
          Name: participants.find((p) => p.id === x)?.Name,
        }))
      );
    }
  }, [eventDataExisting, participants, bottles]);

  useEffect(() => {
    try {
      const fetchParticipants = async () => {
        setLoading(true);
        const data = await getAllUsers();
        setParticipants(data);
        setLoading(false);
      };
      fetchParticipants();
    } catch (error) {
      toast.error("An error occured while fetching participants");
    }
  }, []);

  useEffect(() => {
    try {
      const fetchBottles = async () => {
        setLoading(true);
        const data = await getBottles();
        // console.log("bottles:", data);
        setBottles(data);
        setLoading(false);
      };
      fetchBottles();
    } catch (error) {
      toast.error("An error occured while fetching bottles");
    }
  }, []);

  const clearEvent = () => {
    setEvent({ Date: new Date() });
    setBottlesForEvent([]);
    setParticipantsForEvent([]);
    setSelectedBottles([])
    document.getElementById("title").value = "";
    document.getElementById("description").value = "";
  };

  const handleAddEvent = () => {
    // Check if all required fields are filled
    try {
      if (
        !event.Date ||
        participantsForEvent.length === 0 ||
        bottlesForEvent.length === 0 || !event.Title || !event.Description
      ) {
        toast.error("Please fill all the fields");
        return;
      }

      let eventObject = {
        ...event,
        Date: event.Date.toDateString(),
        Participants: participantsForEvent.length,
        Bottles: bottlesForEvent.map((x) => x.id),
        Attempts: participantsForEvent.reduce((acc, curr, index) => {
          acc[curr.id] = {
            Guesses: [],
            Ratings: [],
          };
          return acc;
        }, {}),
      };

      setLoading(true);
      createEvent(eventObject, eventDataExisting?.id)
        .then((res) => {
          toast.success("Event created successfully");
          // clearEvent();
          navigate("/a/events");
        })
        .catch((err) => {
          // show error message
          console.log(err);
          toast.error(err.message || "Error creating event");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      toast.error("An error occured while creating event");
    }
  };

  return (
    <div className="flex flex-col md:flex-col gap-4 p-6 mt-[calc(100dvh/8)] place-self-start w-full h-min">
      <div className="flex flex-col md:flex-row gap-4 md:h-full auto-cols-auto text-white">
        <div className={`${sectionStyle} md:min-h-min md:w-full`}>
          <h3 className="add-event-h3">Title</h3>
          <input
            type="text"
            id="title"
            className="h-1/6 input-primary"
            onChange={(e) => setEvent({ ...event, Title: e.target.value })}
          />
          <h3 className="add-event-h3">Description</h3>
          <textarea
            id="description"
            rows="4"
            className="h-2/5 input-primary"
            placeholder="Write a short description of the event here..."
            onChange={(e) =>
              setEvent({ ...event, Description: e.target.value })
            }
          ></textarea>
        </div>
        <div className={`${sectionStyle} w-full md:w-min  md:h-min p-0 `}>
          <h3 className="add-event-h3">Date</h3>
          <DatePicker
            // minDate={new Date()}
            selected={new Date(event.Date)}
            onChange={(e) => setEvent({ ...event, Date: new Date(e) })}
            dateFormat="Pp"
            inline
            fixedHeight
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className={`${sectionStyle} flex flex-col h-full`}>
          <h3 className="add-event-h3">BOTTLES</h3>
          <WineSelector
            setBottlesForEvent={setBottlesForEvent}
            setLoading={setLoading}
            bottlesForEvent={bottlesForEvent}
            bottles={bottles}
            setBottles={setBottles}
            selectedBottles={selectedBottles}
            setSelectedBottles={setSelectedBottles}
          />
        </div>

        <div className={`${sectionStyle} flex flex-col h-full`}>
          <h3 className="add-event-h3">PARTICIPANTS</h3>
          <ParticipantSelector
            setParticipantsForEvent={setParticipantsForEvent}
            setLoading={setLoading}
            participantsForEvent={participantsForEvent}
            participants={participants}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:h-full auto-cols-auto text-white">
        <div
          className={`${sectionStyle} md:min-h-min w-full flex justify-end items-center`}
        >
          <span className="font-semibold text-lg mr-6">
            You have unsaved changes
          </span>
          <button
            className="mx-4 text-sm h-min bg-white text-navy_blue border-navy_blue border py-2 px-4 rounded-lg hover:bg-penn_blue/20"
            onClick={clearEvent}
          >
            Clear
          </button>

          <button
            className="text-sm h-min bg-navy_blue text-white py-2 px-4 rounded-lg hover:bg-penn_blue"
            onClick={handleAddEvent}
          >
            Submit
          </button>
        </div>
      </div>

    </div>
  );
};

export default AddEvent;
