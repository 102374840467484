// src/components/Admin.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import SmallStatCard from "../../components/SmallStatCard";
import { Calendar, MoveUpRight, Users2, Wine } from "lucide-react";
import { getAllUsers } from "../../services/userService";
import { getRecentEvents } from "../../services/eventService";
import ProfileImage from "../../components/ProfileImage";
import { useNavigate } from "react-router-dom";
import DashBoardSmallStats from "../../components/DashBoardSmallStats";
import FAQdisplay from "../../components/FAQdisplay";
import RecentEvents from "../../components/RecentEvents";
import CurrentEvents from "../../components/HapenningNow";

const sectionStyle =
  " text-navy_blue p-4 rounded-lg bg-white/90 backdrop-blur-sm ";
const rowWithCols =
  "w-full flex flex-col md:flex-row gap-4  auto-cols-auto items-center  ";

const Admin = ({ setLoading }) => {
  return (
    <div className="flex flex-col md:flex-col gap-4 p-6 mt-[calc(100dvh/8)] place-self-start w-full ">
      <DashBoardSmallStats
        sectionStyle={sectionStyle}
        setLoading={setLoading}
      />

      <div className="flex flex-col md:flex-row gap-4 md:h-full auto-cols-auto ">
        <div className={`${sectionStyle} md:min-h-min md:w-full text-xl hidden md:block`}>
          <CurrentEvents setLoading={setLoading} />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:h-full auto-cols-auto ">
        <div className={`${sectionStyle} md:min-h-min md:w-full text-xl hidden md:block`}>
          <FAQdisplay />
        </div>
        {/* 
        <div className={`${sectionStyle} md:min-h-min md:w-full text-xl`}>
          <RecentEvents setLoading={setLoading} />
        </div> */}
      </div>
    </div>
  );
};

export default Admin;
