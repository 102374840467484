import { BookText } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBottlesByIDs } from "../../services/bottleService";
import { useAuth } from "../../context/authContext";
import { addAttempt } from "../../services/gameService";
import { shuffleArray } from "../../utils/suffle";
import letterByIndex from "../../utils/letters";
import { toast } from "react-toastify";

const PlayEvent = ({ setLoading }) => {
  const location = useLocation();
  const { event } = location.state || {};
  const [bottles, setBottles] = useState([]);
  const [bottlesCorrect, setBottlesCorrect] = useState([]);
  const [selectedBottles, setSelectedBottles] = useState([]);
  const [ratingsGiven, setRatingsGiven] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (event) {
      setLoading(true);
      getBottlesByIDs(event.Bottles)
        .then((data) => {
          setBottlesCorrect(data.map((bottle) => bottle?.id));
          setSelectedBottles(data.map(() => ""));
          setRatingsGiven(data.map(() => 0));
          setBottles(shuffleArray(data));
        })
        .catch((error) => {
          console.error("Error getting bottles: ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [event, setLoading]);

  const handleSubmit = () => {
    if (selectedBottles.includes("") || ratingsGiven.includes(0)) {
      toast.error("Please fill all the fields");
      return;
    }

    let attempt = {
      Guesses: selectedBottles,
      Ratings: ratingsGiven,
      Time: new Date().toISOString(),
    };
    setLoading(true);
    addAttempt(attempt, event.id, auth.user.uid, bottlesCorrect)
      .then(() => {
        console.log("Attempt added");
        toast.success("Attempt added successfully");

        navigate("/u/events");
      })
      .catch((error) => {
        console.error("Error adding attempt: ", error);
        toast.error(JSON.stringify(error) || "Error adding attempt");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClear = () => {
    setSelectedBottles(bottles.map(() => ""));
    setRatingsGiven(bottles.map(() => 0));
  };

  const handleBottleSelect = (e, index) => {
    let temp = [...selectedBottles];
    let nBottle = e.target.value;

    if (temp.includes(nBottle)) {
      let i = temp.indexOf(nBottle);
      temp[i] = "";
    }

    temp[index] = nBottle;
    setSelectedBottles(temp);
  };

  const handleRate = (e, index) => {
    let temp = [...ratingsGiven];

    let given = parseInt(e) + 1;
    temp = temp.map((rating) => {
      if (rating == given) {
        return 0;
      } else {
        return rating;
      }
    });

    if (temp[index] == given) {
      temp[index] = 0;
    } else {
      temp[index] = given;
    }
    setRatingsGiven(temp);
  };

  return (
    <div className=" text-navy_blue place-content-evenly flex flex-wrap gap-4 p-6 min-h-[calc(100dvh/8*7)] mt-[calc(100dvh/8)] md:mx-10 rounded-t-xl w-screen">
      {bottles.map((bottle, index) => (
        <div
          key={index}
          className="bg-white/80 w-full md:w-96 justify-center rounded-xl h-fit"
        >
          <div className="flex flex-row text-xl font-bold bg-navy_blue rounded-t-xl p-3 text-white">
            <div className="ml-4">Glass {letterByIndex(index)}</div>
          </div>

          <div className="flex flex-col justify-between mt-2 p-3 ">
            <span className="text-lg">Guess The Glass</span>
            <select
              className="rounded-md p-2 text-navy_blue w-full min-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
              id={"select" + index}
              value={selectedBottles[index]}
              onChange={(e) => {
                handleBottleSelect(e, index);
              }}
            >
              <option value="">Select a bottle</option>
              {bottles.map((bottle, bottleIndex) => (
                <option
                  key={"b" + bottleIndex}
                  value={bottle?.id}
                  disabled={selectedBottles.includes(bottle?.id)}
                >
                  {`${bottle?.name || "Unknown"} (${bottle?.year})`}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col justify-between  p-3">
            <span className="text-lg">Rate The Glass</span>
            <div className=" flex flex-wrap gap-4 py-2  text-navy_blue ">
              {bottles.map((bottle, bottleIndex) => (
                <button
                  className={
                    " rounded-full w-10 h-10 px-3  " +
                    (ratingsGiven[index] == bottleIndex + 1
                      ? "text-white bg-navy_blue"
                      : "bg-white ")
                  }
                  onClick={(e) => {
                    handleRate(bottleIndex, index);
                  }}
                  key={"rate" + index + bottleIndex}
                >
                  {bottleIndex + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="bg-navy_blue/80 min-w-40 p-3 w-full md:w-96 justify-center rounded-xl h-fit text-white">
        <div>
          <span className="italic">Instructions: </span>
          <ol className="font-light list-decimal p-6">
            <li>Guess the glass by selecting it from the dropdown</li>
            <li>
              Rate the glass by clicking on the rating{" "}
              <span className="font-thin italic">
                ( 1 is best and 5 is the worst )
              </span>
            </li>
            <li>
              Submit your guesses and ratings by clicking the submit button
            </li>
          </ol>
        </div>

        <button
          className=" bg-red-500 p-2 px-4 rounded-md mt-4 mr-6"
          onClick={handleClear}
        >
          Clear
        </button>

        <button
          className=" bg-green-500 p-2 px-4 rounded-md mt-4"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PlayEvent;
