import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { db } from "../config/firebase";

const getCounts = async () => {
    let data = {};

    
    try {
      const userRef = collection(db, "users");
      const userQueryAll = query(userRef, where("Role", "==", "user"));
      const userQueryInactive = query(userRef, where("Status", "==", "inactive"), where("Role", "==", "user"));

      // Create an array of promises
      const [winesCount, eventsCount, usersCountAll, usersCountInactive] = await Promise.all([
        getCountFromServer(collection(db, "wines")),
        getCountFromServer(collection(db, "events")),
        getCountFromServer(userQueryAll),
        getCountFromServer(userQueryInactive)
      ]);
  
      // Process the results
      data.wines = winesCount.data().count;
      data.events = eventsCount.data().count;
      data.users = usersCountAll.data().count - usersCountInactive.data().count;


      

  
      return data;
    } catch (error) {
      console.error("Error getting counts:", error);
      throw new Error("Error getting counts");
    }
  };
  

export { getCounts };
