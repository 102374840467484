import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Admin from "../pages/Admin/Admin";
import Events from "../pages/Admin/Events";
import Participants from "../pages/Admin/Participants";
import AddEvent from "../pages/Admin/AddEvent";
import WIneBackground from "../components/WIneBackground";
import Event from "../pages/Event";
import NavBar from "../components/NavBar";
import LoadingScreen from "../components/LoadingScreen";
import EditProfile from "../pages/EditProfile";
import Wines from "../pages/Admin/Wines";
import FeedbackForm from "../pages/Admin/FeedbackAdmin"
import FAQManager from "../pages/Admin/FAQManager";
function AdminInterface() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <NavBar navigate={navigate}  setLoading={setLoading}/>
      {loading && <LoadingScreen />}
      <div className="relative  w-full items-center flex  z-0  min-h-screen">
        <div className="">
          <WIneBackground isBlurred={true} />
        </div>
        <div className="relative flex items-center h-full w-full">
          <Routes>
            <Route path="/" element={<Admin setLoading={setLoading} />} />
            <Route path="/events" element={<Events setLoading={setLoading} />} />
            <Route path="/participants" element={<Participants setLoading={setLoading} />} />
            <Route path="/event/new" element={<AddEvent setLoading={setLoading} />} />
            <Route path="/event/edit" element={<AddEvent setLoading={setLoading} />} />
            <Route path="/event/feedback" element={<FeedbackForm setLoading={setLoading} />} />
            <Route path="/event" element={<Event setLoading={setLoading} />} />
            <Route path="/profile" element={<EditProfile setLoading={setLoading} />} />
            <Route path="/wines" element={<Wines setLoading={setLoading} />} />
            <Route path="/faqs" element={<FAQManager setLoading={setLoading} />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default AdminInterface;
