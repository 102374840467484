import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEventFeedback,
  setEventFeedback,
} from "../../services/feedbackService";
import { toast } from "react-toastify";

const FeedbackForm = ({ setLoading }) => {
  const [feedback, setFeedback] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const location = useLocation();
  const { event } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeedback = async () => {
      setLoading(true);
      try {
        let feedbackData = await getEventFeedback(event.id);
        if (feedbackData) {
          setFeedback(feedbackData.feedbackText);
          setSelectedImages(
            feedbackData.images.map((url) => ({ url, isNew: false }))
          );
        }
      } catch (error) {
        console.error("Error fetching feedback:", error);
        toast.error("Error fetching feedback");
      } finally {
        setLoading(false);
      }
    };

    if (event) {
      fetchFeedback();
    }
  }, [event, setLoading]);

  const handleClear = () => {
    setFeedback("");
    setSelectedImages([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const feedbackData = {
        eventID: event.id,
        feedbackText: feedback,
        images: selectedImages,
      };
      await setEventFeedback(feedbackData);
      toast.success("Feedback submitted successfully");
      handleClear();
      navigate(`/a/event`, { state: { event: event } });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Error submitting feedback");
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => {
      const url = URL.createObjectURL(file);
      return { file, url, isNew: true };
    });
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const removeImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div className="w-full mx-auto md:mx-10 bg-white shadow-md rounded-lg mt-[calc(100dvh/8)] p-6">
      <h2 className="text-xl font-semibold mb-4">
        Submit Feedback For The Event:{" "}
        <span className="text-navy_blue font-bold">{event?.Title}</span> &nbsp;{" "}
        <span className="text-sm text-gray-500">({event?.Date})</span>
      </h2>
      <textarea
        className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
        rows="4"
        placeholder="Enter your feedback..."
        value={feedback}
        onChange={handleFeedbackChange}
      />
      <input
        type="file"
        multiple
        accept="image/*"
        className="mb-4"
        onChange={handleImageChange}
      />
      <div className="flex flex-wrap gap-2 mb-4">
        {selectedImages.map((imageObj, index) => (
          <div key={index} className="relative">
            <img
              src={imageObj.url}
              alt={`Selected ${index}`}
              className="w-20 h-20 object-cover rounded"
            />
            <button
              onClick={() => removeImage(index)}
              className="w-min h-min absolute top-0 right-0 bg-red-600 text-white p-0 rounded-full focus:outline-none"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-4">
        <button
          className="w-max px-10 bg-red-500 text-white py-2 rounded-lg hover:bg-red-600"
          onClick={handleClear}
        >
          Clear
        </button>
        <button
          className="w-max px-10 bg-green-500 text-white py-2 rounded-lg hover:bg-green-600"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FeedbackForm;
