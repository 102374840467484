import React, { useEffect, useState } from "react";
import { getUserDataByID } from "../services/userService";
import ProfileImage from "./ProfileImage";
import { toast } from "react-toastify";
const WinnerCard = ({ data, name, setLoading }) => {
  const [player, setPlayer] = useState({
    Name: "",
    Email: "",
    Profile_Picture_URL: "",
  });

  useEffect(() => {
    try {
      
      async function getPlayerData() {
        setLoading(true);
        try {
          if (data?.id) {
            let user = await getUserDataByID(data.id);
            setPlayer(user);
          }
        }
        catch (error) {
          toast.error("An error occured while fetching player data");
        }
        finally {
          setLoading(false);
        }
      }
      getPlayerData();
    }
    catch (error) {
      toast.error("An error occured while fetching player data");
    }
  }, [data]);

  return (
    <div className="relative">
      <div className="relative z-50">
        <div className="flex flex-row">
          <div className="h-10 w-10 mx-2 place-self-center">
            
            <ProfileImage
              alt={player?.Name}
              src={player?.Profile_Picture_URL }
              className="relative inline-block h-full -translate-x-0.5 !rounded-full object-cover object-center w-full"
            />
          </div>
          <div>
            <h3 className="font-semibold text-lg">{player.Name}{player.Status == "inactive" ? (
                            <span className="ml-2 font-thin bg-red-700 text-sm text-white px-2 rounded-2xl py-px">
                              Inactive
                            </span>
                          ) : null}</h3>
            <p className="text-sm text-neutral-700/50">{player.Email}</p>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4 mt-4">
          <div className="flex flex-col">
            <div className="text-neutral-700/50 uppercase text-sm">Correct</div>
            <div>
              {data?.Result.Correct}/{data?.Result.Total}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-neutral-700/50 uppercase text-sm">Score</div>
            <div>{data?.Result.Score}</div>
          </div>
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 -z-10 opacity-40 md:opacity-100">
        <img src={`/${name}.png`} className="h-full w-full object-cover" />
      </div>
    </div>
  );
};

export default WinnerCard;
