import React from "react";
import ProfileImage from "./ProfileImage";

const ProfileChip = ({ user, removeUser }) => {
  return (
    <div className="min-h-10 text-base relative grid select-none items-center whitespace-nowrap rounded-full bg-navy_blue/20 px-3 py-1.5 text-navy_blue">
      <div className="absolute top-2/4 left-1.5 h-5 w-5 -translate-y-3/4 ml-2">
        <ProfileImage
          alt={user?.Name}
          src={user?.Profile_Picture_URL}
          className="relative inline-block h-full w-full -translate-x-0.5 !rounded-full object-cover object-center"
        />
      </div>
      <span className="mr-5 ml-10">
        <p className="block antialiased font-medium leading-none capitalize">
          {user.Name}
        </p>
      </span>
      <button
        className="absolute top-1/2 right-0 mx-px h-8 w-8 -translate-y-1/2 select-none rounded-full text-center transition-all hover:bg-white/50 z-10"
        type="button"
        onClick={() => {
          console.log("Removing user with ID: ", user.id);
          removeUser(user.id);
        }}
      >
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-4 h-4"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default ProfileChip;
