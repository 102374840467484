import {  doc, getDoc, updateDoc } from "firebase/firestore";
import {  ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { authFb, db, store } from "../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

export const fetchUserData = async (uid) => {
  const userDoc = doc(db, "users", uid);
  const userSnapshot = await getDoc(userDoc);

  if (userSnapshot.exists()) {
    return userSnapshot.data();
  } else {
    throw new Error("User not found");
  }
};

export const updateUserData = async (uid, updatedData) => {
  const userDoc = doc(db, "users", uid);

  await updateDoc(userDoc, updatedData);
};

export const uploadProfilePicture = async (uid, file) => {
  const fileRef = ref(store, `profile_pictures/${uid}`);

  await uploadBytes(fileRef, file);
  return getDownloadURL(fileRef);
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(authFb, email)
      .then(() => {
        console.log("Password reset email sent");
        return true;
      })
      .catch((error) => {
        throw new Error(error.message || "Error sending password reset email");
      });
  } catch (error) {
    throw new Error(error.message || "Error sending password reset email");
  }
};
