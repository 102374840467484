import React from "react";

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center z-50 bg-white bg-opacity-75">
      <div className="flex flex-col justify-center items-center">
        <img src="/icegif-2353.gif" alt="loading" className="h-[calc(100dvh/5)] rounded-xl" />
        {/* <h1 className="text-black text-xl mt-8">Loading...</h1> */}
      </div>
    </div>
  );
};

export default LoadingScreen;
