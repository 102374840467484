import React, { useEffect, useMemo, useState } from "react";
import { dynamicSort } from "../utils/dynamicSort";
import { Link, useNavigate } from "react-router-dom";
import { statusValues } from "../utils/consts";
import {
  CalendarPlus,
  CalendarSearch,
  NotebookPen,
  Pencil,
  Play,
  Search,
  Trash2,
} from "lucide-react";

import { getEvents } from "../services/eventService";
import PopupDivGen from "./GeneralPopup";
import Popup from "reactjs-popup";
import { deleteEventByID } from "../services/eventService";
import { useAuth } from "../context/authContext";
import { toast } from "react-toastify";
import EventDescription from "./EventDescriptionPopup";

const participantsHeadersAdmin = {
  Title: "Title",
  Participants: "No of Participants",
  AverageScore: "Average Score",
  Status: "Status",
};

const participantsHeadersUser = {
  Title: "Title",
  // Status: "Status",
};

const statusChip = {
  Current: "bg-green-400/50 text-green-900",
  Past: "bg-red-400/50 text-red-900",
  Upcoming: "bg-yellow-400/50 text-yellow-900",
};

const tabs = [
  "All",
  statusValues.CURRENT,
  statusValues.PAST,
  statusValues.UPCOMING,
];

const TableReact = ({ setLoading }) => {
  const [activeColumn, setActiveColumn] = useState(["Name"]);
  const [sortingColumn, setSortingColumn] = useState(["Name"]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [searchString, setSearchString] = useState("");
  const [events, setEvents] = useState([]);
  const [sortingData, setSortingData] = useState(events);
  const [showPopup, setShowPopup] = useState(false);
  const [eventToDelete, setEventToDelete] = useState({});
  const [eventToPlay, setEventToPlay] = useState(null);

  const navigate = useNavigate();
  const auth = useAuth();

  const participantsHeaders =
    auth.user?.role === "admin"
      ? participantsHeadersAdmin
      : participantsHeadersUser;

  useEffect(() => {
    try {
      console.log("Fetching Data for User ", auth.user);
      setLoading(true);
      getEvents(auth.user)
        .then((data) => {
          setEvents(data);
          setSortingData(data);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      toast.error("An error occured while fetching events");
    }
  }, [auth.user]);

  const handleEditEvent = (data) => {
    console.log(data);
    navigate(`/a/event/edit`, { state: { eventDataExisting: data } });
  };

  const deleteEvent = () => {
    try {
      console.log("deleting event" + eventToDelete.id);
      setLoading(true);
      deleteEventByID(eventToDelete)
        .then((res) => {
          setShowPopup(false);
          setEvents(events.filter((event) => event.id !== eventToDelete.id));
          setSortingData(
            sortingData.filter((event) => event.id !== eventToDelete.id)
          );
          toast.success("Event deleted successfully");
        })
        .catch((err) => {
          toast.error("An error occured while deleting event");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("An error occured while deleting event");
    }
  };

  const handleDeleteEvent = (data) => {
    setShowPopup(true);
    setEventToDelete(data);
  };

  const handleFeedbackEvent = (data) => {
    console.log(data);
    navigate(`/a/event/feedback`, { state: { event: data } });
  };

  const handleViewEvent = (data) => {
    console.log(data);
    if (auth.user?.role === "user") {
      if (!data.Attempts[auth.user.uid]?.Guesses?.length) {
        toast.error("You have not attempted this event yet");
        return;
      }
    }
    let u = String(auth.user?.role).charAt(0);
    navigate(`/${u}/event`, { state: { event: data } });
  };

  const sortByColumn = (column) => {
    console.log(column);
    if (sortingColumn?.includes(column)) {
      const sortData = sortingData.slice().sort(dynamicSort(column, "asc"));
      setSortingData(sortData);
      setSortingColumn([]);
    } else {
      const sortData = sortingData.slice().sort(dynamicSort(column, "desc"));
      setSortingData(sortData);
      setSortingColumn([`${column}`]);
    }
    setActiveColumn([`${column}`]);
  };

  useMemo(() => {
    const sortedProducts = events.slice().sort((a, b) => a.Price - b.Price);
    setSortingData(sortedProducts);
  }, []);

  useEffect(() => {
    const filterData = events.filter((data) => {
      if (activeFilter === "All") {
        return data;
      } else {
        return data.Status === activeFilter;
      }
    });
    setSortingData(filterData);
  }, [activeFilter]);

  useEffect(() => {
    const filterData = events.filter((data) => {
      if (searchString === "") {
        return data;
      } else {
        return data?.Title.toLowerCase().includes(searchString.toLowerCase());
      }
    });
    setSortingData(filterData);
  }, [searchString]);

  return (
    <div className="w-full h-full   flex flex-col items-center justify-center    md:place-self-center  text-navy_blue">
      <div className="w-full bg-white md:m-6 rounded-md p-8 ">
        <div className="flex flex-row place-content-between w-full">
          <h1 className="text-2xl font-medium mb-8">
            Events List
            <div className="text-navy_blue/50 text-base">
              See Information about all events.
            </div>
          </h1>

          {auth.user?.role === "admin" ? (
            <div className="place-content-center  text-white h-fit mt-2 text-sm">
              <button
                className="flex items-center max-h-min bg-navy_blue rounded-md p-2"
                onClick={() => navigate("/a/event/new")}
              >
                <CalendarPlus size={20} />
                <span className="ml-2">ADD EVENT</span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="flex flex-col md:flex-row md:place-content-between w-full mb-6">
          <div className="flex flex-row place-content-between bg-navy_blue-900/50 rounded-lg p-1 gap-4 mb-2 md:mb-0 md:mr-2">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`cursor-pointer px-2 py-1 rounded-md ${
                  activeFilter === tab
                    ? "bg-navy_blue text-white"
                    : "text-navy_blue/50"
                }`}
                onClick={() => setActiveFilter(tab)}
              >
                {tab}
              </div>
            ))}
          </div>

          <div
            id="search"
            className="flex flex-row place-content-between bg-navy_blue-900/50 rounded-lg p-1 gap-4"
          >
            <input
              type="text"
              placeholder="Search"
              className="bg-transparent text-navy_blue p-1"
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button className="bg-navy_blue rounded-md p-1 text-white">
              {/* <img src="/search.svg" alt="Icon" className="h-4 w-4" /> */}
              <Search size={20} />
            </button>
          </div>
        </div>

        <div className="h-full overflow-y-scroll overflow-x-scroll md:overflow-auto  mt-2">
          <table className="w-full table-auto overflow-scroll md:overflow-auto  text-left font-inter border-separate border-spacing-y-0 borer ">
            <thead className="bg-egyptian_blue-500 rounded-lg text-base text-white font-semibold  ">
              <tr className="">
                {Object.keys(participantsHeaders).map((header, index) => (
                  <th
                    className="py-3 px-3 text-white whitespace-nowrap group "
                    id={index}
                  >
                    <div className="flex items-center">
                      <svg
                        className={` h-4 cursor-pointer ${
                          activeColumn?.includes(header)
                            ? "text-white"
                            : "text-[#BCBDBE] group-hover:text-black rotate-180"
                        } ${
                          sortingColumn?.includes(header)
                            ? "rotate-180"
                            : "rotate-0"
                        }
           `}
                        onClick={() => sortByColumn(header)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 14l-7 7m0 0l-7-7m7 7V3"
                        />
                      </svg>
                      <span
                        className="cursor-pointer pl-1 text-center"
                        onClick={() => sortByColumn(header)}
                      >
                        {participantsHeaders[header]}
                      </span>
                    </div>
                  </th>
                ))}

                <th className="py-3 px-3 text-white whitespace-nowrap group ">
                  <div className="flex items-center">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {sortingData.length ? (
                sortingData?.map((data, index) => (
                  <tr
                    key={index}
                    className="hover:bg-navy_blue/20 cursor-pointer"
                    onClick={() => handleViewEvent(data)}
                  >
                    <td className=" py-2 px-3 font-normal text-base border-t text-left min-w-40 md:min-w-32">
                      {data?.Title}
                      <p>{data?.Date}</p>
                      {auth.user?.role === "user" ? (
                        <div
                          className={
                            " rounded-md px-1 py-0.5  text-sm w-max mt-1 " +
                            statusChip[data?.Status]
                          }
                        >
                          {data?.Status}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    {auth.user?.role === "admin" ? (
                      <>
                        <td className="py-2 px-3 text-base font-normal border-t ">
                          {data?.Participants}
                        </td>
                        <td className="py-2 px-3 text-base font-normal border-t ">
                          {data?.AverageScore}
                        </td>
                        <td className="py-5 px-4 text-base font-normal border-t">
                          <div
                            className={
                              " rounded-md px-1 py-0.5  text-sm w-full " +
                              statusChip[data?.Status]
                            }
                          >
                            {data?.Status}
                          </div>
                        </td>
                      </>
                    ) : (
                      ""
                    )}

                    {auth.user?.role === "admin" ? (
                      <td className="py-5 px-4 text-base font-normal border-t">
                        <div className="flex flex-row gap-2">
                          <button
                            className="bg-red-600 text-white rounded-full p-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDeleteEvent(data);
                            }}
                            title="Delete Event"
                          >
                            <Trash2 size={20} />
                          </button>
                          <button
                            className={
                              "bg-green-600 text-white rounded-full p-2" +
                              (data?.Status === "Past" ? " hidden" : "")
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEditEvent(data);
                            }}
                            title="Edit Event"
                          >
                            <Pencil size={20} />
                          </button>
                          <button
                            className={
                              "bg-yellow-600 text-white rounded-full p-2" +
                              (data?.Status === "Upcoming" ? " hidden" : "")
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              handleFeedbackEvent(data);
                            }}
                            title="Submit Feedback"
                          >
                            <NotebookPen size={20} />
                          </button>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {auth.user?.role === "user" ? (
                      <td className="py-5 px-4 text-base font-normal border-t">
                        <div className="gap-2">
                          <button
                            className={
                              "text-navy_blue font-bold py-2 px-4 rounded hover:bg-navy_blue/20" +
                              (data?.Status != "Current"
                                ? " opacity-50 cursor-not-allowed"
                                : "")
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              setEventToPlay(data);
                            }}
                            disabled={data?.Status != "Current"}
                          >
                            <div className="flex items-center gap-2">
                              <div className="mr-2 p-2 bg-white rounded-full">
                                <Play
                                  size={20}
                                  strokeWidth={2}
                                  absoluteStrokeWidth
                                />
                              </div>
                              Play Now
                            </div>
                          </button>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))
              ) : (
                <tr className=" p-6">No Data</tr>
              )}
              <tr>
                <td colSpan={6} className="border-t"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Popup
        position="center center"
        closeOnDocumentClick
        modal
        contentStyle={{
          border: "none",
          background: "none",
          width: "max-content",
        }}
        open={showPopup}
      >
        <PopupDivGen
          message={`Are you sure you want to delete this event?`}
          setshowPopup={setShowPopup}
          onYes={deleteEvent}
        />
      </Popup>

      <Popup
        position="center center"
        closeOnDocumentClick
        modal
        contentStyle={{
          border: "none",
          background: "none",
          width: "max-content",
        }}
        open={eventToPlay ? true : false}
        onClose={() => setEventToPlay(null)}
      >
        <EventDescription
          event={eventToPlay}
          setEventToPlay={setEventToPlay}
          onYes={() => {
            navigate(`/u/event/play`, { state: { event: eventToPlay } });
          }}
        />
      </Popup>
    </div>
  );
};
export default TableReact;
