import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Admin from "../pages/Admin/Admin";
import User from "../pages/User/User";
import Events from "../pages/Admin/Events";
import Participants from "../pages/Admin/Participants";
import WIneBackground from "../components/WIneBackground";
import Event from "../pages/Event";
import NavBar from "../components/NavBar";
import LoadingScreen from "../components/LoadingScreen";
import PlayEvent from "../pages/User/PlayEvent";
import EditProfile from "../pages/EditProfile";

function UserInterface() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <NavBar navigate={navigate} setLoading={setLoading}/>
      {loading && <LoadingScreen />}
      <div className="relative  w-full items-center flex  z-0  min-h-screen">
        <div className="">
          <WIneBackground isBlurred={true} />
        </div>
        <div className="relative flex items-center h-full w-full">
          <Routes>
            <Route path="/" element={<User setLoading={setLoading} />} />
            <Route path="/events" element={<Events setLoading={setLoading} />} />
            <Route path="/event" element={<Event setLoading={setLoading} />} />
            <Route path="/event/play" element={<PlayEvent setLoading={setLoading} />} />
            <Route path="/profile" element={<EditProfile setLoading={setLoading} />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default UserInterface;
