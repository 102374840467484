import React, { useEffect, useMemo, useState } from "react";
import { dynamicSort } from "../utils/dynamicSort";

import Popup from "reactjs-popup";
import PopupDiv from "./AddMemberPopup";
import {
  createuser,
  deleteUserByID,
  getAllUsers,
} from "../services/userService";
import { Search, Trash2, UserPlus } from "lucide-react";
import ProfileImage from "./ProfileImage";
import { useAuth } from "../context/authContext";
import PopupDivGen from "./GeneralPopup";
import { toast } from "react-toastify";

const participantsHeaders = {
  Name: "Member",
  DateJoined: "Date Joined",
  NoOfEvents: "No of Events",
  Score: "Score",
  Status: "Status",
};

const statusChip = {
  Active: "bg-green-400/50 text-green-900",
  Inactive: "bg-red-400/50 text-red-900",
};

const tabs = ["All", "Active", "Inactive"];

const SortableTableParticipants = ({ setLoading }) => {
  const [activeColumn, setActiveColumn] = useState(["Name"]);
  const [sortingColumn, setSortingColumn] = useState(["Name"]);
  const [showPopup, setShowPopup] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [sortingData, setSortingData] = useState(participants);

  const [showPopup2, setShowPopup2] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [activeFilter, setActiveFilter] = useState("");
  const [searchString, setSearchString] = useState("");

  const auth = useAuth();

  useEffect(() => {
    const filterData = participants.filter((data) => {
      if (activeFilter === "All") {
        return data;
      } else if (activeFilter === "Inactive") {
        return data.Status === "inactive";
      } else {
        return data.Status != "inactive";
      }
    });
    setSortingData(filterData);
  }, [activeFilter]);

  useEffect(() => {
    const filterData = participants.filter((data) => {
      if (searchString === "") {
        return data;
      } else {
        return data?.Name.toLowerCase().includes(searchString.toLowerCase());
      }
    });
    setSortingData(filterData);
  }, [searchString]);

  useEffect(() => {
    try {
      setLoading(true);
      getAllUsers()
        .then((data) => {
          let f_data = data.filter((user) => user.Role === "user");
          setParticipants(f_data);
          setSortingData(f_data);
          setActiveFilter("Active");
          console.log(f_data);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const handleAddUser = () => {
    console.log("Add User");
    createuser();
  };

  const sortByColumn = (column) => {
    if (sortingColumn?.includes(column)) {
      const sortData = sortingData.slice().sort(dynamicSort(column, "asc"));
      setSortingData(sortData);
      setSortingColumn([]);
    } else {
      const sortData = sortingData.slice().sort(dynamicSort(column, "desc"));
      setSortingData(sortData);
      setSortingColumn([`${column}`]);
    }
    setActiveColumn([`${column}`]);
  };

  useMemo(() => {
    const sortedProducts = participants
      .slice()
      .sort((a, b) => a.Price - b.Price);
    setSortingData(sortedProducts);
  }, []);

  const deleteUser = () => {
    try {
      setLoading(true);
      deleteUserByID(userToDelete.id)
        .then((res) => {
          toast.success("User deleted successfully");
          setShowPopup(false);

          setParticipants(
            participants.filter((user) => user.id !== userToDelete.id)
          );
          setSortingData(
            sortingData.filter((user) => user.id !== userToDelete.id)
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occured while deleting user");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("An error occured while deleting user");
    }
  };

  const handleDeleteUser = (data) => {
    setShowPopup2(true);
    setUserToDelete(data);
  };

  return (
    <div className="w-full h-full   flex flex-col items-center justify-center    md:place-self-center  text-navy_blue">
      <div className="w-full bg-white md:m-6 rounded-md p-8 ">
        <div className="flex flex-row place-content-between w-full">
          <h1 className="text-2xl font-medium mb-8">
            Members List
            <div className="text-navy_blue/50 text-base">
              See Information about all members.
            </div>
          </h1>

          {auth.user?.role === "admin" && (
            <div className="place-content-center  text-white h-fit mt-2 text-sm">
              <button
                className="flex items-center max-h-min bg-navy_blue rounded-md p-2"
                onClick={() => setShowPopup(true)}
              >
                {/* <im?g src="/add-user.svg" alt="Icon" className="mr-2 h-4 w-4" /> */}
                <UserPlus size={20} />
                <span className="ml-2">Add Member</span>
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row md:place-content-between w-full mb-6">
          <div className="flex flex-row place-content-between bg-navy_blue-900/50 rounded-lg p-1 gap-4 mb-2 md:mb-0 md:mr-2">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`cursor-pointer px-2 py-1 rounded-md ${
                  activeFilter === tab
                    ? "bg-navy_blue text-white"
                    : "text-navy_blue/50"
                }`}
                onClick={() => setActiveFilter(tab)}
              >
                {tab}
              </div>
            ))}
          </div>

          <div
            id="search"
            className="flex flex-row place-content-between bg-navy_blue-900/50 rounded-lg p-1 gap-4"
          >
            <input
              type="text"
              placeholder="Search"
              className="bg-transparent text-navy_blue p-1"
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button className="bg-navy_blue rounded-md p-1 text-white">
              {/* <img src="/search.svg" alt="Icon" className="h-4 w-4" /> */}
              <Search size={20} />
            </button>
          </div>
        </div>

        <div className="h-screen overflow-y-scroll overflow-x-scroll md:overflow-auto  mt-2">
          <table className="w-full table-auto overflow-scroll md:overflow-auto  text-left font-inter border-separate border-spacing-y-0 borer ">
            <thead className="bg-egyptian_blue-500 rounded-lg text-base text-white font-semibold  ">
              <tr className="">
                {Object.keys(participantsHeaders).map((header, index) => (
                  <th
                    className="py-3 px-3 text-white whitespace-nowrap group "
                    id={index}
                  >
                    <div className="flex items-center">
                      <svg
                        className={` h-4 cursor-pointer ${
                          activeColumn?.includes(header)
                            ? "text-white"
                            : "text-[#BCBDBE] group-hover:text-black rotate-180"
                        } ${
                          sortingColumn?.includes(header)
                            ? "rotate-180"
                            : "rotate-0"
                        }
           `}
                        onClick={() => sortByColumn(header)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 14l-7 7m0 0l-7-7m7 7V3"
                        />
                      </svg>
                      <span
                        className="cursor-pointer pl-1 text-center"
                        onClick={() => sortByColumn(header)}
                      >
                        {participantsHeaders[header]}
                      </span>
                    </div>
                  </th>
                ))}

                {auth.user?.role === "admin" ? (
                  <th className="py-3 px-3 text-white whitespace-nowrap group ">
                    <div className="flex items-center">
                      <span className="cursor-pointer pl-1 text-center">
                        Actions
                      </span>
                    </div>
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody className="text-center">
              {sortingData?.map((data, index) => 
              {
                let sts = data?.Status == "inactive" ? "Inactive" : "Active";
                return (
                <tr key={index}>
                  <td className=" py-2 px-3 font-normal text-base border-t whitespace-nowrap flex text-left ">
                    <div className="h-10 w-10 mx-2  place-self-center">
                      <ProfileImage
                        alt={data?.Name}
                        src={data?.Profile_Picture_URL}
                        className="relative inline-block h-full w-full -translate-x-0.5 !rounded-full object-cover object-center"
                      />
                    </div>
                    <div>
                      {data?.Name}
                      <br></br>
                      <span className="text-navy_blue/50">{data?.Email}</span>
                    </div>
                  </td>
                  <td className="py-2 px-3 font-normal text-base border-t whitespace-nowrap">
                    <div>
                      {new Date(
                        data?.Joined_Date.seconds * 1000 +
                          data?.Joined_Date.nanoseconds / 1000000
                      ).toLocaleDateString()}
                    </div>
                  </td>
                  <td className="py-2 px-3 text-base font-normal border-t whitespace-nowrap ">
                    {data?.NoOfEvents}
                  </td>
                  <td className="py-2 px-3 text-base font-normal border-t ">
                    {data?.Score}
                  </td>
                  <td className="py-2 px-3 text-base font-normal border-t ">
                    <span className={"rounded-full px-2 " +  statusChip[sts] }>{sts}</span>
                  </td>

                  {auth.user?.role === "admin" && data?.Status != "inactive" ? (
                    <td className="py-5 px-4 text-base font-normal border-t">
                      <button
                        className="bg-red-600 text-white rounded-full p-2"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteUser(data);
                        }}
                      >
                        <Trash2 size={20} />
                      </button>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              )})}
              <tr>
                <td colSpan={6} className="border-t"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Popup
        position="center center"
        closeOnDocumentClick
        modal
        contentStyle={{
          border: "none",
          background: "none",
          width: "fit-content",
        }}
        open={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <PopupDiv setshowPopup={setShowPopup} setLoading={setLoading} />
      </Popup>

      <Popup
        position="center center"
        closeOnDocumentClick
        modal
        contentStyle={{ border: "none", background: "none",width: "max-content" }}
        open={showPopup2}
        onClose={() => setShowPopup2(false)}
      >
        <PopupDivGen
          message={`Are you sure you want to delete this user?`}
          setshowPopup={setShowPopup2}
          onYes={deleteUser}
        />
      </Popup>
    </div>
  );
};
export default SortableTableParticipants;
