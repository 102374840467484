import React from "react";
import { Users2 } from "lucide-react";

const SmallStatCard = ({ name, value, icon, height, width="2/12" }) => {
  const sectionStyle =
    `w-full  text-navy_blue p-2 rounded-lg bg-white/90 backdrop-blur-sm  place-self-start ${height ? `h-[${height}px] `: ""} ${width ? ` md:w-${width}`: ""}`;
  const rowWithCols =
    "w-full flex flex-row gap-4  p-2 ";

  return (
    <div className={sectionStyle }>

      <div
        className={
          rowWithCols +
          " ml-0 place-content-between border-dotted border-b-2 border-black/20 place-items-start"
        }
      >
        <h2 className="font-bold text-6xl text-justify ">{value}</h2>
        <h2 className="font-normal text-xl text-right place-self-end">
          {icon}
        </h2>
      </div>
      <h2 className="font-light text-base text-justify mt-2 ">{name}</h2>
    </div>
  );
};

export default SmallStatCard;
