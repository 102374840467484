import { db } from "../config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import dayjs from "dayjs";
import { calculateScoreOfEvent } from "./scoreService";

const getEventById = async (eventId) => {
  const docRef = doc(db, "events", eventId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return calculateScoreOfEvent([processEventDoc(docSnap)])[0];
  } else {
    throw new Error("No such document!");
  }
};

const getEvents = async (user) => {
  const querySnapshot = await getDocs(collection(db, "events"));
  let data = querySnapshot.docs.map(processEventDoc);

  if (user?.role === "user") {
    data = data.filter((event) =>
      Object.keys(event.Attempts).includes(user.uid)
    );
  }

  return calculateScoreOfEvent(data);
};

const processEventDoc = (doc) => {
  let data = doc.data();

  let status;
  const eventDate = new Date(data.Date);
  const today = new Date();

  // Reset time part to compare only dates
  today.setHours(0, 0, 0, 0);
  eventDate.setHours(0, 0, 0, 0);

  if (eventDate.getTime() === today.getTime()) {
    status = "Current";
  } else if (eventDate.getTime() < today.getTime()) {
    status = "Past";
  } else {
    status = "Upcoming";
  }

  return { ...data, id: doc.id, Status: status };
};

const getRecentEvents = async () => {
  const today = dayjs().format("YYYY-MM-DD");
  const eventsRef = collection(db, "events");

  const todayQuery = query(eventsRef, where("Date", "==", today));
  const todaySnapshot = await getDocs(todayQuery);
  let events = todaySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), Status: "Current" }));

  console.log("Events for today: ", events);
  // If less than 3 events for today, fetch additional recent events
  if (events.length < 3) {
    const recentQuery = query(eventsRef, orderBy("Date", "desc"), limit(5));
    const recentSnapshot = await getDocs(recentQuery);
    const recentEvents = recentSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Filter out events already included
    const filteredRecentEvents = recentEvents.filter(
      (event) => !events.some((e) => e.id === event.id)
    );

    // Add enough recent events to make the total count 3
    events = [...events, ...filteredRecentEvents.slice(0, 3 - events.length)];
  }

  return calculateScoreOfEvent(events);
};

const checkEventExists = async (title) => {
  const q = query(collection(db, "events"), where("Title", "==", title));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].id;
  }
  return null;
};

const createEvent = async (event, id) => {
  console.log("Creating event: ", event);
  // const existingEventId = await checkEventExists(event.Title);
  // if (existingEventId) {
  //   throw new Error(`Event with the title "${event.Title}" already exists with ID ${existingEventId}`);
  // }
  if (id) {
    let eventRef = doc(db, "events", id);
    await updateDoc(eventRef, event);
    return id;
  } else {
    const docRef = await addDoc(collection(db, "events"), event);
    return docRef.id;
  }
};

const deleteEventByID = async (event) => {
  console.log("Deleting event: ", event);
  let participantIDs = Object.keys(event.Attempts);

  // Delete event from each participant
  participantIDs.forEach(async (participantID) => {
    let userdocRef = doc(db, "users", participantID);
    let userDoc = await getDoc(userdocRef);
    let user = userDoc.data();
    console.log("User: ", user);

    if (user.Events) {
      delete user.Events[event.id];
      await updateDoc(userdocRef, { Events: user.Events });
    }
  });

  await deleteDoc(doc(db, "events", event.id));
};

export {
  getEvents,
  getRecentEvents,
  createEvent,
  deleteEventByID,
  checkEventExists,
  getEventById,
};
