import React, { useRef, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { statusValues } from "../utils/consts";
import SmallStatCard from "../components/SmallStatCard";
import { Users2, Wine } from "lucide-react";
import WinnerCard from "../components/WinnerCard";
import LeaderboardTable from "../components/LeaderBoardEvent";
import Countdown from "../components/CountDown";
import WineRankings from "../components/TableWines";

import TableWines from "../components/TableWines";
import FeedbackDisplay from "../components/FeedbackDisplay";
import { getEventById } from "../services/eventService";

const Event = ({ setLoading }) => {
  const location = useLocation();
  const { event } = location.state || {};
  const eventID = event?.id;
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(event);

  const divRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [attempts, setAttempts] = useState([]);
  const [top3, setTop3] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        let data = await getEventById(eventID);
        console.log("Event data: ", data);
        setEventData(data);

        let attempts = Object.values(eventData?.Attempts).sort((a, b) => {
          const scoreDifference = b?.Result.Score - a?.Result.Score;
          if (scoreDifference !== 0) {
            return scoreDifference;
          } else {
            const timeA = a?.Time ? new Date(a.Time).getTime() : 0;
            const timeB = b?.Time ? new Date(b.Time).getTime() : 0;
            return timeA - timeB; // ascending order of time
          }
        });

        let top3 = attempts?.slice(0, 3) || [];

        setAttempts(attempts);
        setTop3(top3);
      } catch (error) {
        console.error("Error fetching event:", error);
      } finally {
        setLoading(false);
      }
    };

    if (eventID) {
      fetchEvent();
    }
  }, [eventID]);

  useEffect(() => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  }, []);

  useEffect(() => {
    if (!eventData) {
      navigate("/a/events");
    }
  }, []);

  const sectionStyle =
    "w-full  text-navy_blue p-4 rounded-lg bg-white/90 backdrop-blur-sm md:h-full ";
  const rowWithCols =
    "w-full flex flex-col md:flex-row gap-4  auto-cols-auto items-center  ";

  return (
    <div className="flex flex-col md:flex-col gap-4 p-6 mt-[calc(100dvh/8)]  w-full ">
      <div name="top" className={rowWithCols}>
        <div ref={divRef} className={sectionStyle + "md:w-11/12"}>
          <div className={rowWithCols + " ml-0 place-content-between "}>
            <h2 className="font-bold text-2xl text-justify ">
              {eventData?.Title}
              {/* <p className="font-light text-sm">{event?.id}</p> */}
            </h2>

            <h2 className="font-normal text-xl text-right place-self-end">
              {}
              {eventData?.Status == statusValues.PAST
                ? "Event finished on "
                : eventData?.Status == statusValues.CURRENT
                ? "Event is hapenning "
                : "Event will start on "}
              <br />
              {new Date(eventData?.Date).toLocaleDateString()}
            </h2>
          </div>
          <h2 className="font-light text-base text-justify mt-2 ">
            {eventData?.Description}
          </h2>
        </div>
        <SmallStatCard
          name="Total Participants"
          value={Object.keys(eventData?.Attempts).length || 0}
          icon={<Users2 size={56} />}
          height={height}
          width="1/12"
        />
        <SmallStatCard
          name="Bottles of Wines"
          value={eventData?.Bottles.length || 0}
          icon={<Wine size={56} />}
          height={height}
          width="1/12"
        />
      </div>
      {eventData?.Status != statusValues.UPCOMING ? (
        <>
          <div name="winners" className={rowWithCols}>
            <div
              className={
                sectionStyle +
                "md:w-4/12" +
                (top3[0]?.Guesses.length ? "" : " hidden")
              }
            >
              <WinnerCard data={top3[0]} name="1st" setLoading={setLoading} />
            </div>
            <div
              className={
                sectionStyle +
                "md:w-4/12" +
                (top3[1]?.Guesses.length ? "" : " hidden")
              }
            >
              <WinnerCard data={top3[1]} name="2nd" setLoading={setLoading} />
            </div>
            <div
              className={
                sectionStyle +
                "md:w-4/12" +
                (top3[2]?.Guesses.length ? "" : " hidden")
              }
            >
              <WinnerCard data={top3[2]} name="3rd" setLoading={setLoading} />
            </div>
          </div>
          <div name="ranking" className={rowWithCols}>
            <div className={sectionStyle + "md:w-full bg-black"}>
              <LeaderboardTable users={attempts} wines={eventData.Bottles} />
            </div>
          </div>
          <TableWines
            wines={eventData?.WineRatings}
            setLoading={setLoading}
            rowWithCols={rowWithCols}
            sectionStyle={sectionStyle}
          />

          <FeedbackDisplay
            rowWithCols
            sectionStyle
            eventID={eventData?.id}
            setLoading={setLoading}
          />
        </>
      ) : (
        <Countdown eventDate={eventData?.Date} wines={eventData.WineRatings} />
      )}
    </div>
  );
};

export default Event;
