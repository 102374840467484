
function shuffleArray(arrayT) {
    let array = [...arrayT]
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array
    
}


const stringToColour = (str) => {
    try {
        let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
  
    let colour = '';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, '0');
    }
  
    // Calculate luminance to check if the color is too dark
    const r = parseInt(colour.substr(0, 2), 16);
    const g = parseInt(colour.substr(2, 2), 16);
    const b = parseInt(colour.substr(4, 2), 16);
    
    // Formula to calculate luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  
    // Check if the color is too dark, if so return a different color (e.g., light grey)
    if (luminance < 40) {
      return 'd3d3d3'; // Light grey color
    }
  
    return colour;
    }
    catch (error) {
        return 'd3d3d3'
    }
  };
  
export { shuffleArray, stringToColour }