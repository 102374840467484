import React, { useEffect, useState } from "react";


const PopupDivWine = ({ event, setEventToPlay, onYes }) => {

  const closeModal = () => {
    setEventToPlay(null);
  };

  const onClickYes = () => {
    onYes();
    setEventToPlay(null);
  };

  return (
    <div className=" bg-white shadow dark:bg-gray-700 m-0 w-full" >
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="popup-modal"
        onClick={closeModal}
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="p-4 md:p-5 text-center text-white">
      <div className="text-center text-gray-900 dark:text-white">
        <h2 className="text-2xl font-semibold mb-4">Get ready to participate in this event!</h2>
        <h3 className="text-xl font-bold mb-2">{event?.Title}</h3>
        <p className="text-md mb-6">{event?.Description}</p>
      </div>
        
        <button
          data-modal-hide="popup-modal"
          type="button"
          className="mt-4 text-white bg-navy_blue-600 hover:bg-navy_blue-800 focus:ring-4 focus:outline-none focus:ring-navy_blue-300 dark:focus:ring-navy_blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
          onClick={onClickYes}
        >
          Okay, I Want to Play
        </button>
        <button
          data-modal-hide="popup-modal"
          type="button"
          className="mt-4 py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={closeModal}
        >
          No, I'm Good
        </button>
      </div>
    </div>
  );
};

export default PopupDivWine;
