import React, { useState, useEffect } from "react";
import { getAuth, updatePassword, updateProfile } from "firebase/auth";
import {
  fetchUserData,
  resetPassword,
  updateUserData,
  uploadProfilePicture,
} from "../services/profileService";
import { useAuth } from "../context/authContext";
import { toast } from "react-toastify";

const EditProfile = ({ setLoading }) => {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  const auth = useAuth();
  const currentUser = auth.user;

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetchUserData(currentUser.uid)
        .then((userData) => {
          setUser(userData);
          setName(userData.Name || "");
          setPhoneNumber(userData.Phone_Number || "");
          setAddress(userData.Address || "");
        })
        .catch((error) => {
          toast.error("Error fetching user data");
        })
        .finally(() => setLoading(false));
    }
  }, [currentUser]);

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      const updatedData = {
        Name: name,
        Phone_Number: phoneNumber,
        Address: address,
      };

      if (profilePicture) {
        const profilePictureUrl = await uploadProfilePicture(
          currentUser.uid,
          profilePicture
        );
        updatedData.Profile_Picture_URL = profilePictureUrl;
      }

      await updateUserData(currentUser.uid, updatedData);
      toast.success("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile: ", error);
      toast.error("Error updating profile");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      await resetPassword(currentUser.username);
      toast.success("Password reset email sent");
    } catch (error) {
      console.error("Error updating password: ", error);
      toast.error("Error updating password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-20 flex flex-col gap-8 md:flex-row place-content-center w-full mx-4">
      <div className="max-w-md ml-auto bg-white shadow-md rounded-lg p-6 ">
        <h1 className="text-2xl font-bold mb-4">Update Profile</h1>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Name:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number:
          </label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Address:
          </label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Profile Picture:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <button
          onClick={handleUpdateProfile}
          className="w-full py-2 px-4 bg-navy_blue text-white font-semibold rounded-md hover:bg-navy_blue-700"
        >
          Update Profile
        </button>
      </div>
      <div className="max-w-md  mr-auto bg-white shadow-md rounded-lg p-6 h-max">
        <h1 className="text-2xl font-bold mb-4">Update Password</h1>

        <div className="mb-4">
          An email will be sent to{" "}
          <span className="text-navy_blue font-semibold">
            {currentUser?.username}
          </span>{" "}
          with a link to reset your password.
        </div>

        <button
          onClick={handleUpdatePassword}
          className="w-full py-2 px-4 bg-navy_blue text-white font-semibold rounded-md hover:bg-navy_blue-700"
        >
          Send Password Reset Email
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
