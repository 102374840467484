import { Pencil, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { addFAQ, getFAQs, updateFAQ } from "../../services/faqService";
import { toast } from "react-toastify";

const FAQManager = ({ setLoading }) => {
  const [faqs, setFaqs] = useState([]);
  const [newFAQ, setNewFAQ] = useState({ question: "", answer: "" });
  const [editingFAQ, setEditingFAQ] = useState(null);

  useEffect(() => {
    const fetchFAQs = async () => {
      setLoading(true);
      try {
        // Fetch FAQs from the server
        const data = await getFAQs();
        setFaqs(data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        toast.error("Error fetching FAQs");
      } finally {
        setLoading(false);
      }
    };
    fetchFAQs();
  }, []);

  const handleAddFAQ = async () => {
    setLoading(true);
    try {
      if (newFAQ.question && newFAQ.answer) {
        await addFAQ(newFAQ);
        setFaqs([...faqs, { ...newFAQ, id: Date.now() }]);
        setNewFAQ({ question: "", answer: "" });
      } else {
        toast.error("Please fill in both question and answer fields");
      }
    } catch (error) {
      console.error("Error adding FAQ:", error);
      toast.error("Error adding FAQ");
    } finally {
      setLoading(false);
    }
  };

  const handleEditFAQ = (id) => {
    const faqToEdit = faqs.find((faq) => faq.id === id);
    setEditingFAQ(faqToEdit);
    setNewFAQ({ question: "", answer: "" });
  };

  const handleUpdateFAQ = async () => {
    setLoading(true);
    try {
      if (editingFAQ.question && editingFAQ.answer) {
        await updateFAQ(editingFAQ.id, editingFAQ);
        setFaqs(
          faqs.map((faq) => (faq.id === editingFAQ.id ? editingFAQ : faq))
        );
        setEditingFAQ(null);
      } else {
        toast.error("Please fill in both question and answer fields");
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
      toast.error("Error updating FAQ");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFAQ = (id) => {
    setFaqs(faqs.filter((faq) => faq.id !== id));
  };

  return (
    <div className="w-full md:mx-10 flex flex-col md:flex-row space-x-4 bg-white mt-[calc(100dvh/8)] p-6 rounded-lg text-navy_blue">
      <div className="md:w-1/2 h-[calc(100dvh/8*6)] overflow-y-auto border rounded-lg p-4 shadow-sm mb-6">
        <h1 className="text-2xl font-bold mb-4">FAQs</h1>
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className="flex items-center justify-between mb-4 p-4 border rounded-lg shadow-sm"
          >
            <div>
              <h2 className="font-semibold">{faq.question}</h2>
              <p>{faq.answer}</p>
            </div>
            <div className="flex space-x-2">
              <button
                className="bg-red-600 text-white rounded-full p-2"
                onClick={() => handleDeleteFAQ(faq.id)}
                title="Delete Event"
              >
                <Trash2 size={20} />
              </button>
              <button
                className={"bg-green-600 text-white rounded-full p-2"}
                onClick={() => handleEditFAQ(faq.id)}
                title="Edit Event"
              >
                <Pencil size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Add/Edit FAQ Form */}
      <div className="md:w-1/2 p-4 border rounded-lg shadow-sm">
        <h2 className="text-2xl font-bold mb-4">
          {editingFAQ ? "Edit FAQ" : "Add New FAQ"}
        </h2>
        <div className="mb-4">
          <input
            type="text"
            className="w-full p-2 border rounded mb-2"
            placeholder="Question"
            value={editingFAQ ? editingFAQ.question : newFAQ.question}
            onChange={(e) =>
              editingFAQ
                ? setEditingFAQ({ ...editingFAQ, question: e.target.value })
                : setNewFAQ({ ...newFAQ, question: e.target.value })
            }
          />
          <textarea
            className="w-full p-2 border rounded mb-2"
            placeholder="Answer"
            value={editingFAQ ? editingFAQ.answer : newFAQ.answer}
            onChange={(e) =>
              editingFAQ
                ? setEditingFAQ({ ...editingFAQ, answer: e.target.value })
                : setNewFAQ({ ...newFAQ, answer: e.target.value })
            }
          ></textarea>
        </div>
        <button
          className="px-4 py-2 bg-navy_blue-500 text-white rounded"
          onClick={editingFAQ ? handleUpdateFAQ : handleAddFAQ}
        >
          {editingFAQ ? "Update FAQ" : "Add FAQ"}
        </button>
        {editingFAQ && (
          <button
            className="px-4 py-2 ml-2 bg-gray-500 text-white rounded"
            onClick={() => setEditingFAQ(null)}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default FAQManager;
