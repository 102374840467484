import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PopupDiv from "./AddWinePopup";
import { getBottles, addBottle } from "../services/bottleService";
import { toast } from "react-toastify";
import { Trash2 } from "lucide-react";
import { BsTrash2, BsTrash3 } from "react-icons/bs";
import letterByIndex from "../utils/letters";

const WineSelector = ({
  setBottlesForEvent,
  setLoading,
  bottlesForEvent,
  bottles,
  setBottles,
  selectedBottles,
  setSelectedBottles,
}) => {
  const [currentSearch, setCurrentSearch] = useState("");
  const [wineToEdit, setWineToEdit] = useState(null);

  useEffect(() => {
    if (selectedBottles.length === 0 && bottles.length > 0) {
      setSelectedBottles(bottlesForEvent);
    }
  }, [bottlesForEvent]);

  const handleSetSelectedBottles = (bts) => {
    setSelectedBottles(bts);
    setBottlesForEvent(bts);
  };

  // adding a new bottle to the collection
  const handleAddBottle = async () => {
    try {
      let bottle = wineToEdit;

      const addBottleToCollection = async () => {
        setLoading(true);
        let bid = await addBottle(bottle);
        setLoading(false);
        return bid;
      };
      let id = await addBottleToCollection();

      bottle = { id, ...bottle };
      console.log("bottle:", [...selectedBottles, bottle]);
      setBottles([...bottles, bottle]);
      handleSetSelectedBottles([...selectedBottles, bottle]);

      toast.success("Adding bottle successful!");
    } catch (error) {
      toast.error("Error adding new bottle");
      setLoading(false);
    }
  };

  // functions for draggable
  const handleToggleBottle = (bottle) => {
    if (!bottle) return;

    const isSelected = selectedBottles.find((b) => b.id === bottle.id);
    if (!isSelected) {
      handleSetSelectedBottles([...selectedBottles, bottle]);
    } else {
      handleSetSelectedBottles(
        selectedBottles.filter((b) => b.id !== bottle.id)
      );
    }

    setCurrentSearch("");
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedBottles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleSetSelectedBottles(items);

    console.log("selectedBottles:", items);
  };

  // functions for search

  const handleSearch = (string, results) => {
    setCurrentSearch(string);
  };

  const formatResult = (item) => {
    return (
      <div className=" w-full h-min">
        <p>{item.name}</p>
        <p className="text-sm font-thin text-gray-500">{item.year}</p>
      </div>
    );
  };

  return (
    <div className="h-full pb-10">
      <div className="flex flex-col my-2 ">
        <label className="my-4">
          Select The Wine Bottles You Want To Add To The Event:
        </label>

        <div className="w-full flex flex-row gap-2">
          <div className="w-5/6">
            <ReactSearchAutocomplete
              items={bottles}
              onSelect={handleToggleBottle}
              onSearch={handleSearch}
              formatResult={formatResult}
              showIcon={false}
              showNoResults={true}
              inputSearchString={currentSearch}
            />
          </div>
          <button
            className={
              currentSearch && !bottles.find((b) => currentSearch === b.name)
                ? "btn-primary-add-event"
                : "btn-primary-disabled"
            }
            onClick={() => setWineToEdit({ name: currentSearch })}
          >
            Add New
          </button>
          <Popup
            position="center center"
            closeOnDocumentClick
            modal
            contentStyle={{
              border: "none",
              background: "none",
              width: "max-content",
            }}
            open={wineToEdit ? true : false}
            onClose={() => setWineToEdit(null)}
          >
            <PopupDiv
              wine={wineToEdit}
              setWine={setWineToEdit}
              setshowPopup={setWineToEdit}
              onYes={handleAddBottle}
            />
          </Popup>
        </div>
      </div>

      <h3 className="my-4">
        Selected Bottles:{" "}
        <span className="italic text-periwinkle ml-2">
          Click and drag to reorder
        </span>{" "}
      </h3>
      {/* {JSON.stringify(selectedBottles)} */}
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="selected-bottles">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="w-full flex flex-col"
            >
              {selectedBottles.map((bottle, index) => (
                <Draggable
                  key={bottle.id}
                  draggableId={bottle.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: "8px",
                        position: "auto",
                        top: "unset",
                        ...provided.dragHandleProps,
                      }}
                    >
                      <div className="flex flex-row gap-2">
                        <div
                          key={bottle.id}
                          className="flex flex-row w-full h-10 bg-navy_blue/20 rounded-lg p-2"
                        >
                          <div className="flex flex-row w-full h-full items-center gap-2">
                            <div className="flex w-1/6 h-full items-center justify-center">
                              {letterByIndex(index)}
                            </div>
                            <div className="flex w-5/6 h-full items-center">
                            {`${bottle?.name || "Unknown"} (${bottle?.year})`}
                            </div>
                          </div>
                        </div>

                        <button
                          onClick={() => handleToggleBottle(bottle)}
                          type="button"
                          className="text-white bg-navy_blue p-2 rounded-lg hover:bg-penn_blue focus:outline-2 "
                        >
                          <BsTrash3 />
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

export default WineSelector;
