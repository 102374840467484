import emailjs from "@emailjs/browser";

const sendEmail = async (data) => {
  let mailData = {
    name: data.Name,
    email: data.Email,
    password: data.Password,
  };

  await emailjs
    .send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      mailData,
      {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      }
    )
    .then(
      () => {
        console.log("SUCCESS!");
      },
      (error) => {
        console.log("FAILED...", error);
        throw new Error("Error sending email");
      }
    );
};

export { sendEmail };
