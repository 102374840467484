import React, { useEffect, useState } from "react";
import { getEvents, getRecentEvents } from "../services/eventService";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";

const CurrentEvents = ({ setLoading }) => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const data = await getEvents(auth.user);
        console.log(data);
        setCurrentEvents(data.filter((event) => event.Status === "Current"));
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  if (currentEvents.length === 0) {
    return (
      <div className="p-4 bg-white rounded-lg w-full ">
        <div className="text-2xl font-semibold text-center">
          No events happening now
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-2">
        <h2 className="text-2xl font-semibold mb-4">Happening Now</h2>
        <div className="flex flex-wrap gap-4 ">
          {currentEvents.map((event) => (
            <div
              key={event.id}
              className="w-full md:w-auto bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
              onClick={() => {
                navigate(`/u/event/play`, { state: { event: event } });
              }}
            >
              <h2 className="text-xl font-semibold mb-2">{event.Title}</h2>
              <p className="text-gray-700">{event.Description}</p>
              <p className="text-gray-700 text-sm mt-4">Click to play</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default CurrentEvents;
