import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "reactjs-popup/dist/index.css";
import ProfileChip from "./ProfileChip";


const ParticipantSelector = ({
  participantsForEvent,
  setParticipantsForEvent,
  setLoading,
  participants,
}) => {
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [currentSearch, setCurrentSearch] = useState("");

  useEffect(() => {
    setSelectedParticipants(participantsForEvent);
  }, [participantsForEvent]);

  const handleSetSelectedParticipants = (participants) => {
    setSelectedParticipants(participants);
    setParticipantsForEvent(participants);
  };

  const selectAllParticipants = () => {
    console.log("Selecting all participants");
    handleSetSelectedParticipants(participants.filter((p) => p?.Status != "inactive" && p?.Role != "admin"));
  };

  const removeUser = (userID) => {
    console.log("Removing user with ID: ", userID);
    const newParticipants = selectedParticipants.filter((p) => p.id !== userID);
    handleSetSelectedParticipants(newParticipants);
  };

  // functions for draggable
  const handleToggleBottle = (bottle) => {
    if (!bottle) return;

    const isSelected = selectedParticipants.find((b) => b.id === bottle.id);
    if (!isSelected) {
      handleSetSelectedParticipants([...selectedParticipants, bottle]);
    }
    setCurrentSearch("");
  };

  // functions for search

  const handleSearch = (string, results) => {
    setCurrentSearch(string);
  };

  const formatResult = (item) => {
    return (
      <div className="flex flex-row w-full h-min bg-white z-50">
        {item.Name}
      </div>
    );
  };

  return (
    <div className="h-full pb-10 md:min-h-96 ">
      <div className="flex flex-col my-2 ">
        <label className="my-4">Select The Friends You Want To Invite:</label>

        <div className="w-full flex flex-row gap-2 ">
          <div className="w-5/6">
            <ReactSearchAutocomplete
              items={participants.filter((p) => p?.Status != "inactive")}
              onSelect={handleToggleBottle}
              onSearch={handleSearch}
              formatResult={formatResult}
              showIcon={false}
              inputSearchString={currentSearch}
              fuseOptions={{ keys: ["Name"] }}
              resultStringKeyName="Name"
              
            />
          </div>
          <button
            className="btn-primary-add-event"
            onClick={selectAllParticipants}
          >
            Add All
          </button>
        </div>
      </div>

      <h3 className="my-4">Selected Participants:</h3>
      <div className="flex flex-wrap gap-2">
        {selectedParticipants.map((user, index) => (
          <ProfileChip user={user} removeUser={removeUser} />
        ))}
      </div>
    </div>
  );
};

export default ParticipantSelector;
