import React from 'react';

const Medal = ({ title, year, description, icon, bgColor }) => {
    return (
      <div className={`my-2  h-full  rounded overflow-hidden shadow-lg p-6 ${bgColor}`}>
        <div className="flex items-center justify-center mb-4">
          <div className="text-6xl text-white">{icon}</div>
        </div>
        <div className="text-center">
          <h2 className="text-xl font-bold text-white mb-2">{title}</h2>
         
          <p className="text-white">{description}</p>
          <p className="text-sm font-thin text-white">{year}</p>
        </div>
      </div>
    );
  };

export default Medal