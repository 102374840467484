import Cookies from 'js-cookie';
import { authFb } from "../config/firebase";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getUserDataByID } from './userService';

let currentUser = null;


export const login = async (userData) => {

  
  let username = userData.email;
  let password = userData.password;
  console.log(username, password);

  let cred  = await signInWithEmailAndPassword(authFb, username, password);
  console.log(cred);

  let userID = cred.user.uid;
  let fetchedUserData = await getUserDataByID(userID);
  
  let email = cred.user.email || "";
  let currentUser = {
    uid: cred.user.uid,
    email: email,
    role: fetchedUserData.Role,
    username: email,
    profile_picture: fetchedUserData.Profile_Picture_URL,
    name: fetchedUserData.Name,
  }
  
  Cookies.set('wtem', JSON.stringify(currentUser) || null, { expires: 7 });
  return currentUser;
};

export const logout = async () => {
  await authFb.signOut();
  currentUser = null;
  Cookies.remove('wtem');
};

export const getCurrentUser = () => {
  console.log("current user ", authFb.currentUser)
  const name = Cookies.get('wtem');
  
  try {
    return JSON.parse(name);
  }
  catch (e) {
    return null
  }


};
