import React, { useEffect, useState } from "react";
import { getRecentEvents } from "../services/eventService";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { MoveUpRight } from "lucide-react";

const RecentEvents = ({ setLoading, sectionStyle }) => {
  const [recentEvents, setRecentEvents] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        let events = await getRecentEvents();
        setRecentEvents(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className={`${sectionStyle} md:min-h-min md:w-full`}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-800">RECENT EVENTS</h1>
        <button
          className="  text-navy_blue font-bold py-2 px-4 rounded hover:bg-navy_blue/20"
          onClick={() => {
            let u = String(auth.user?.role).charAt(0);
            navigate(`/${u}/events`);
          }}
        >
          <div className="flex items-center gap-2">
            <div className="mr-2 p-2 bg-white rounded-full">
              <MoveUpRight size={20} strokeWidth={2} absoluteStrokeWidth />
            </div>
            SEE ALL
          </div>
        </button>
      </div>

      <div className="flex flex-col md:flex-row gap-2 h-full ">
        {recentEvents.map((event, index) => (
          <div className=" p-2 px-4 rounded-lg shadow-md h-fit md:w-1/3 text-white bg-navy_blue/80">
            <h1 className="font-semibold text-lg">{event.Title}</h1>
            <h1 className="font-light mt-4">{event.Description}</h1>
            <h1 className="font-normal mt-2">
              {new Date(event?.Date).toDateString()}
            </h1>
            <button
              className=" mt-4  font-bold py-2 px-4 rounded hover:bg-white/20"
              onClick={() => {
                let u = String(auth.user?.role).charAt(0);
                navigate(`/${u}/event`, { state: { event: event } });
              }}
            >
              <div className="flex  items-center gap-2">
                <div className="mr-2 p-2 bg-white rounded-full text-navy_blue">
                  <MoveUpRight size={15} strokeWidth={2} absoluteStrokeWidth />
                </div>
                {event?.Status == "Current" ? "PLAY NOW" : "SEE DETAILS"}
                {event?.Status}
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentEvents;
